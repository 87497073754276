import React from "react";
import { Select, Form } from "antd";

const { Option } = Select;
const ClinicSelect = ({ clinics, onClinicianChange }) => {
  const sortedClinics = clinics.sort((a, b) =>
    a.clinic_name.localeCompare(b.clinic_name)
  );

  const renderOption = clinic => (
    <Option
      key={clinic.clinic_id}
      value={clinic.clinic_id}
      label={`${clinic.clinic_name} (${clinic.clinic_email})`}
    >
      <div>
        <div>{clinic.clinic_name}</div>
        <div style={{ fontSize: "small", color: "gray" }}>
          {clinic.clinic_email}
        </div>
      </div>
    </Option>
  );

  const filterOption = (input, option) => {
    const clinic = sortedClinics.find(
      clinic => clinic.clinic_id === option.value
    );
    if (clinic) {
      return (
        clinic.clinic_name.toLowerCase().includes(input.toLowerCase()) ||
        clinic.clinic_email.toLowerCase().includes(input.toLowerCase())
      );
    }
    return false;
  };

  return (
    <div>
      <Form.Item
        name="clinicId"
        label="Clinician Name"
        rules={[
          {
            required: true,
            message: "Please select clinician",
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Enter Clinician"
          filterOption={filterOption}
          onChange={onClinicianChange}
          labelInValue
          style={{ width: "100%" }}
          optionLabelProp="label"
        >
          {sortedClinics.map(clinic => renderOption(clinic))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default ClinicSelect;
