import React, { useState, useEffect, useCallback } from "react";
import { Row, Spin, message } from "antd";
import { useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { staffDropDowSelector } from "services/redux/selectors/labStaff.selector";
import PatientListComponent from "./PaientListTable/Table";
import { fetchLOV } from "services/redux/actions/labActions";
import { getPatientList } from "api/lab";
import { getLabStaff, assignStaffMember } from "api/staff";
import {
  getDurationStatusFilter,
  getEndDateFilter,
  getOrderStatusFilter,
  getStartDateFilter,
} from "utils/Lab";
import { capitalizeFirstLetter } from "utils/commonMethod";
function PatientList({ lab, fetchLOV }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const statusParams = queryParams.get("status");

  const [patientsList, setPatientsList] = useState([]);
  const [isPatientTableSpinning, setIsPatientTableSpinning] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState(50);
  const [paginationPage, setPaginationPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [fromDate, setFromDate] = useState(getStartDateFilter(statusParams));
  const [endDate, setEndDate] = useState(getEndDateFilter(statusParams));
  const [orderStatus, setOrderStatus] = useState(() => {
    const filter = getOrderStatusFilter(statusParams);
    return filter;
  });
  const [slaDaysFilter, setSlaDaysFilter] = useState(getDurationStatusFilter(statusParams));
  const [clinicNamesFilter, setClinicNamesFilter] = useState("");
  const [fromDateOrdered, setFromDateOrdered] = useState("");
  const [endDateOrdered, setEndDateOrdered] = useState("");
  const [staffMembers, setStaffMembers] = useState([]);
  const labStaffMembers = useSelector(staffDropDowSelector);

  const _getPatientList = useCallback(async () => {
    setIsPatientTableSpinning(true);

    try {
      const params={
        limit: 5000,
        page: 1,
        //   clinicId: lab.clinicId,
        labId: lab.labId,
        search: searchKey,
        startDate: fromDate,
        endDate: endDate,
        fromDateOrdered: fromDateOrdered,
        endDateOrdered: endDateOrdered,
        orderStatusName: orderStatus.length ? `"${orderStatus}"` : "",
        clinicName: clinicNamesFilter !== "" ? `"${clinicNamesFilter}"` : "",
        slaDaysFilter: slaDaysFilter !== "" ? `"${slaDaysFilter}"` : "",
        // labId:"LAB94912731"
      };
      const resData = await getPatientList(params);
      const list = resData?.data?.body?.data;
      // list.sort(function(a,b){
      //   return new Date(b.product_order_date) - new Date(a.product_order_date)
      // });
      setIsPatientTableSpinning(false);
      setPatientsList(list);
      setTotalRecords(list.length);
      //   setPatientsList(list.body.data);
    } catch (error) {
      console.log("error", error);
      setIsPatientTableSpinning(false);
      message.error("Failed to load patients list");
    }
  }, [
    clinicNamesFilter,
    endDate,
    endDateOrdered,
    fromDate,
    fromDateOrdered,
    lab.labId,
    orderStatus,
    searchKey,
    slaDaysFilter,
  ]);

  const handlePatientAssignee = async (staffId, row) => {
    // console.log("handlePatientAssignee", row);
  

    // if (staffId) {
    await assignStaffMember({
      labStaffId: row?.staffPatientId || "",
      patientId: row.patientDetails.patientID,
      staffId: staffId ? staffId : 0,
    });

    await _getPatientList();
    // }
  };

  useEffect(() => {
    if (lab.labId && (searchKey.length >= 3 || searchKey === "")) _getPatientList();
  }, [lab.labId, searchKey, _getPatientList]);

  useEffect(()=>{
    console.log("lab.reloadPatientList",lab.reloadPatientList);
    if(lab?.reloadPatientList){
      _getPatientList();
    }
  },[lab.reloadPatientList])


  useEffect(() => {
    if (lab.labId) {
      fetchLOV();
      // handleLabStaff(lab.labId);
    }
  }, [lab.labId, fetchLOV]);

  const onSearch = (searchKey) => {
    setSearchKey(searchKey);
    setPaginationPage(1);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationPage(pagination.current);
    setPaginationLimit(pagination.pageSize);
    if (filters && filters.orderStatus?.length > 0) setOrderStatus(filters.orderStatus.toString());
    else setOrderStatus("");
    if (filters && filters.clinicName?.length > 0)
      setClinicNamesFilter(filters.clinicName.toString());
    else setClinicNamesFilter("");

    if (filters && filters?.sla?.length > 0) {
      setSlaDaysFilter(filters.sla.toString());
    }
  };

  const handleDateFilter = (confirm, start = "", end = "") => {
    setFromDate(start);
    setEndDate(end);
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };
  const handleDateOrderedFilter = (confirm, start = "", end = "") => {
    setFromDateOrdered(start);
    setEndDateOrdered(end);
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };

  return (
    <div>
      <Spin spinning={isPatientTableSpinning}>
        <Row justify="center" style={{ width: "100%" }}>
          <PatientListComponent
            paginationLimit={paginationLimit}
            patientsList={patientsList}
            paginationPage={paginationPage}
            onSearch={onSearch}
            onPagination={handleTableChange}
            handleDateFilter={handleDateFilter}
            totalRecords={totalRecords}
            handleDateOrderedFilter={handleDateOrderedFilter}
            statusParams={statusParams}
            setTotalRecords={setTotalRecords}
            fromDate={fromDate}
            endDate={endDate}
            fromDateOrdered={fromDateOrdered}
            endDateOrdered={endDateOrdered}
            staffMembers={labStaffMembers}
            handlePatientAssignee={handlePatientAssignee}
            getPatients={_getPatientList}
          />
        </Row>
      </Spin>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps, { fetchLOV })(PatientList);
