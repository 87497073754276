import api from "./api";

export const getPreliminaryImagesApi = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patient/resource/getOnboardingImages`,
    params: params,
  });
};

export const deleteScanService = (data) => {
  return api({
    method: "POST",
    url: `/patient/resource/delete`,
    data,
  });
};

export const getPrescriptionScanFilesHistory = (patientId) => {
  return api({
    method: "GET",
   url: `/clinic/scans/getScanHistory/${patientId}`,
  // url: `/clinic/scans/getScanHistory/PATIENT58734592`,

  });
};


export const deletePrescriptionScanFile = (data, clinicId) => {
  return api({
    method: "DELETE",
    url: `/clinic/scans/delete/${clinicId}`,
    // url: `/clinic/scans/delete/CLINIC43417305`,
    data:data

  });
};

export const getPrescriptionScanFiles = (patientId) => {
  return api({
    method: "GET",
     url: `/clinic/scans/getScanFiles/${patientId}`,
  });
};