import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Typography,
  Radio,
  Spin,
  Tooltip,
} from "antd";
// import { PUBLIC_API_BASE_URL_DEV } from "../../services/api/utilities/public";
import { connect, useSelector } from "react-redux";
import { companyType, primaryColor, country as countryName } from "colors-config";
import { labApiTasks } from "models/lab";
import UploadFilesComponent from "components/private/uploadFilesComponent";

import { loadLab } from "services/redux/actions/labActions";
import "assets/styles/components/accountSettingsFormComponent.scss";
import axios from "axios";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getPatientCountryCodesList } from "services/redux/selectors/commonSelectors";

const PUBLIC_API_BASE_URL = process.env.REACT_APP_CLINIC_BASE_URL;

const { Option } = Select;
const { Title } = Typography;

const children = [];
const countries = ["IRELAND", "INDIA", "CHINA", "CANADA"];

// for (let i = 10; i < 36; i++) {
//   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
// }
countries.forEach((country) => {
  children.push(<Option key={country}>{country}</Option>);
});

// })

function AccountSettingsFormComponent({
  labDetails,
  lab,
  mode,
  fileListTermsAndConditions,
  fileListForm,
  onProfileFormSubmit,
  profileForm,
}) {
  // const [form] = Form.useForm();
  
  const [isSpinning, setIsSpinning] = useState(true);

  const patientCountriesList = useSelector(getPatientCountryCodesList);
  const [labServices, setLabServices] = useState([]);

  //   const [selectedService, setSelectedService] = useState([]);
  const [locationSearchResults, setLocationSearchResults] = useState([]);
  //   const [locationSearchValue, setLocationSearchValue] = useState("");
  const [dataFormUrl, setDataFormUrl] = useState("");
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState("");

  let formValues = {
    prefix: lab.labCountryCode,
    phone: lab.labPhoneNumber,
    country:  countryName,
    email: lab.labEmail,
    labServiceID: labServices[0]?.lab_service_id,
  };
  
  const formInitialValues = mode === "view" ? labDetails : formValues;
  // const onFinish = (labDetailsForm) => {
  //   // console.log(labDetailsForm, "clinic details");
  //   // next();
  //   setIsButtonSpinning(true);

  //   onSubmit(labDetailsForm, setIsButtonSpinning);
  // };

  useEffect(() => {
    async function fetchLabOptions() {
      try {
        // const countryCodesRes = await axios.get(
        //   `${PUBLIC_API_BASE_URL}/clinic/getCountryCodes`
        // );

        const labServicesRes = await labApiTasks.getAll("lab/getLabServices");

        // parseAppointmentNames(res.body.clinicAppointmentNames);
        // setCountryCodes(countryCodesRes.data.body.countryCodes);
        setLabServices(labServicesRes.body.success.labServices);
      } catch (error) {
        console.error(error);
      }
    }

    fetchLabOptions();
  }, []);

  // useEffect(() => {
  //   _getPolicyLinks();
  //   async function fetchCountries() {
  //     try {
  //       const res = await labApiTasks.getAll("getCountries");
  //       setCountry(res?.body?.countries);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   fetchCountries();
  // }, []);
  function removeAWSKey(url) {
    return url.slice(0, url.indexOf("?"));
  }
  const _getPolicyLinks = async () => {
    try {
      const res = await labApiTasks.getAll("lab/getLabDefaultResource");
      // console.log(res, "res");
      setDataFormUrl(res.body.data_acquistion_form.url);
      setTermsAndConditionsUrl(res.body.terms_and_conditions.url);
      // setDataFormUrl(removeAWSKey(res.body.data_acquistion_form.url));
      // setTermsAndConditionsUrl(removeAWSKey(res.body.terms_and_conditions.url));
    } catch (error) {
      console.log(error);
    }
  };
  const onRadioChange = (val) => {
    // console.log(val);
  };

  useEffect(() => {
    if (lab.labId && labServices.length && mode !== "view") {
      profileForm.resetFields();

      //   setIsSpinning(false);
    }
    if (
      lab.labId &&
      mode === "view" &&
      Object.keys(labDetails).length &&
      labServices.length
    ) {
      profileForm.resetFields();

      //   setIsSpinning(false);
    }
    // if (Object.keys(clinic).length) {
    //   setIsSpinning(false);
    // }
  }, [lab.labId, labServices, labDetails]);

  const _getZipCode = async (address) => {
    try {
      const zipCodeRes = await labApiTasks.getAll("getZipcode", {
        address: address,
      });

      const postalCode = zipCodeRes.body.zipcode;
      if (postalCode !== "Not Found") {
        return postalCode;
      } else {
        return "";
      }
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  async function onValueChange(val) {
    if (val.address) {
      // if(val.address.length >= 3){
      //   try{
      //     const addressRes = await labApiTasks.getAll("lab/places",{address:val.address})
      //     console.log(addressRes,
      //       "address search")
      //   }catch(error){
      //     console.log(error)
      //   }
      // }
    }
  }

  const handleAddressSearch = async (val) => {
    if (val.length >= 3) {
      try {
        const addressRes = await labApiTasks.getAll("lab/places", {
          address: val,
          country: "india",
        });
        // console.log(addressRes, "address search");
        setLocationSearchResults(addressRes.body.data.suggestions.predictions);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleAddressChange = async (val) => {
    const id = JSON.parse(val).place_id;

    let selectedLocation;
    locationSearchResults.forEach((location) => {
      if (location.place_id === id) {
        selectedLocation = location;
      }
    });

    const country =
      selectedLocation.terms[selectedLocation.terms.length - 1]?.value;
    const state =
      selectedLocation.terms[selectedLocation.terms.length - 2]?.value;
    const city =
      selectedLocation.terms[selectedLocation.terms.length - 3]?.value;
    const county =
      selectedLocation.terms[selectedLocation.terms.length - 4]?.value;

    profileForm.setFieldsValue({ city, country, county });

    const postalCode = await _getZipCode(selectedLocation.description);
    profileForm.setFieldsValue({ postalCode });

    // console.log(selectedLocation, "location");
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        disabled={mode === "view"}
        dropdownClassName="country-dropdown"
        className="country-select"
        size="large"
        dropdownStyle={{ width: "300px" }}
        style={{ width: 110 }}
        labelRender={(props) => {
          return props.label;
        }}
        options={patientCountriesList?.map((code, index) => {
          return {
            ...code,
            key: index,
            value: code.countryCallingCodes,
            title: `${code.emoji} ${code.countryCallingCodes}`,
            label: code.countryCallingCodes,
          };
        })}
        optionRender={(option) => {
          const { data } = option;
          return (
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}
            >
              <span className="emoji-font">{data.emoji}</span>
              <span>{data.countryCallingCodes}</span>
              <span>| {data.name}</span>
            </div>
          );
        }}
        showSearch
        filterOption={(input, option) => {
          const lowerCasedInput = input.toLowerCase();
          return (
            option.value.toLowerCase().includes(lowerCasedInput) ||
            option.name.toLowerCase().includes(lowerCasedInput)
          );
        }}
      />
    </Form.Item>
  );

  return (
    <div className="booking-form-container">
      <div className="booking-form-title-container ">
        <Row>
          <Title className="booking-form-title" level={5}>
            Lab Account Settings
          </Title>
        </Row>
      </div>
      <Row>
        <Form
          style={{
            width: "100%",
          }}
          onValuesChange={onValueChange}
          form={profileForm}
          name="complex-form"
          layout={"vertical"}
          initialValues={formInitialValues}
          onFinish={onProfileFormSubmit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
        >
          <div className="booking-form">
            <Form.Item className="parent-form-item">
              <Form.Item
                label={
                  <span>
                    Lab Name&nbsp;
                    <Tooltip title="No special characters">
                      <InfoCircleOutlined
                        style={{ color: primaryColor }}
                      />
                    </Tooltip>
                  </span>
                }
                name="labName"
                className="inline-form-left"
                rules={[
                  { required: true, message: "Lab name is required" },

                  {
                    max: 50,
                    message: "Lab name cannot be more than 50 characters",
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  marginRight: "0.4rem",

                  textAlign: "left",
                }}
              >
                <Input disabled={mode === "view"} placeholder="Lab Name" />
              </Form.Item>

              <Form.Item
                label="Super Admin Name"
                name="labAdminName"
                rules={[
                  { required: true, message: "Admin name is required" },
                  {
                    max: 30,
                    message: "Admin name cannot be more than 30 characters",
                  },
                ]}
                className="inline-form-right"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  margin: "0 0.5rem 0  2rem ",

                  textAlign: "left",
                }}
              >
                <Input disabled={mode === "view"} placeholder="Admin Name" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="labServiceID"
              label="Services"
              required={true}
              style={{
                marginRight: "0.5rem",
                marginTop: "2rem",
                textAlign: "left",
              }}
            >
              <Radio.Group disabled={mode === "view"} onChange={onRadioChange}>
                {labServices.map((labService) => {
                  return (
                    <Radio
                      key={labService.lab_service_id}
                      value={labService.lab_service_id}
                    >
                      {labService.lab_service}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>

            <Form.Item className="parent-form-item">
              <Form.Item
                name="phone"
                label="Phone Number"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  marginRight: "0.4rem",

                  textAlign: "left",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  () => ({
                    validator(_, value) {
                      const convertedValue = Number(value);
                      if (!value) {
                        return Promise.resolve();
                      } else if (
                        !isNaN(convertedValue) &&
                        (value.length === 9 || value.length === 10)
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject("Invalid phone number");
                    },
                  }),
                ]}
              >
                <Input
                  disabled={mode === "view"}
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                  placeholder="Phone Number"
                />
              </Form.Item>

              <Form.Item
                label="Email Address"
                name="email"
                className="inline-form-right"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  margin: "0 0.5rem 0  2rem ",

                  textAlign: "left",
                }}
                rules={[
                  {
                    type: "email",
                    message: "Invalid email address!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input disabled placeholder="Email Address" />
              </Form.Item>
            </Form.Item>

            <Form.Item className="parent-form-item">
              <Form.Item
                label={
                  <span>
                    Business Registration No.&nbsp;
                    <Tooltip title="Verified by the clinic admin">
                      <InfoCircleOutlined
                        style={{ color: primaryColor }}
                      />
                    </Tooltip>
                  </span>
                }
                name="businessRegistrationNo"
                className="inline-form-left"
                rules={[
                  {
                    required: true,
                    message: "Business Registration Number is required",
                  },
                  // {
                  //   pattern: /^[a-zA-Z0-9]{1,10}$/,
                  //   message: "Input should be valid and 10 characters long",
                  // },
                ]}
                style={{
                  width: "calc(50% - 1.5rem)",
                  marginRight: "0.4rem",
                  marginBottom: "-20px",

                  textAlign: "left",
                }}
              >
                <Input
                  disabled={mode === "view"}
                  placeholder="Business Registration Number"
                />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="address"
              label="Address"
              style={{
                marginRight: "0.5rem",
                marginTop: "4rem",
              }}
              rules={[
                {
                  required: true,
                  message: "Please input your Address!",
                },
                () => ({
                  validator(_, value) {
                    if (value.trim().length !== 0 || !value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Address should be valid!");
                  },
                }),
              ]}
            >
              <Input
                disabled={mode === "view"}
                placeholder="Enter address here"
              />
            </Form.Item>

            <Form.Item className="parent-form-item">
              <Form.Item
                label="City"
                name="city"
                className="inline-form-left"
                rules={[
                  { required: true, message: "City is required" },
                  {
                    max: 30,
                    message: "City name cannot be more than 30 characters",
                  },
                  {
                    pattern:
                      /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
                    message: "Invalid city name",
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  marginRight: "0.4rem",

                  textAlign: "left",
                }}
              >
                <Input disabled={mode === "view"} placeholder="City" />
              </Form.Item>
              <Form.Item
                label="County/Province/State"
                name="county"
                rules={[
                  {
                    max: 30,
                    message:
                      "County/Province/State name cannot be more than 30 characters",
                  },
                  // () => ({
                  //   validator(_, value) {
                  //     if (
                  //       (value.trim().length > 0 && isNaN(value)) ||
                  //       !value
                  //     ) {
                  //       return Promise.resolve();
                  //     }

                  //     return Promise.reject(
                  //       "Should be alphabets or a combination of alphabets and numbers!"
                  //     );
                  //   },
                  // }),
                ]}
                className="inline-form-right"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  margin: "0 0.5rem 0  2rem ",
                  textAlign: "left",
                }}
              >
                <Input
                  disabled={mode === "view"}
                  placeholder="County/Province/State"
                />
              </Form.Item>
            </Form.Item>
            <Form.Item className="parent-form-item">
              <Form.Item
                label="Post/Zip/EIR Code"
                name="postalCode"
                className="inline-form-left"
                rules={[
                  { required: true, message: "Code is required" },
                  () => ({
                    validator(_, value) {
                      if (/\d/.test(value) || !value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "Should be a number or a combination of alphabets and numbers!"
                      );
                    },
                  }),
                  {
                    pattern: /^[a-zA-Z0-9]{2,10}$/,
                    message: "Invalid postal code",
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  marginRight: "0.4rem",

                  textAlign: "left",
                }}
              >
                <Input disabled={mode === "view"} placeholder="Code" />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  { required: true, message: "Country is required" },
                  {
                    max: 30,
                    message: "Country name cannot be more than 30 characters",
                  },
                  {
                    pattern: /^[a-zA-Z ]*$/,
                    message: "Please enter only alphabets",
                  },
                ]}
                className="inline-form-right"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  margin: "0 0.5rem 0  2rem ",
                  textAlign: "left",
                }}
              >
                <Select
                  disabled={mode === "view"}
                  showSearch
                  value={"India"}
                  placeholder="Country"
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  optionFilterProp="children"
                      size="large"
                      options={patientCountriesList?.map((country, i) => {
                        return {
                          ...country,
                          value: country.name,
                          title: country.name,
                          label: (
                            <>
                              <span className="emoji-font">
                                {country.emoji}
                              </span>
                              {` ${country.name}`}
                            </>
                          ),
                          key: i,
                        };
                      })}
                      labelRender={(props) => {
                        return props.title;
                      }}
                      filterOption={(input, option) => {
                        const lowerCasedInput = input.toLowerCase();
                        return option.name.toLowerCase().includes(lowerCasedInput);
                      }}
                >
             
                </Select>
              </Form.Item>
            </Form.Item>
          </div>
          {/* <Form.Item style={{backgroundColor:"red"}}  > */}
          <UploadFilesComponent
            termsAndConditionsUrl={termsAndConditionsUrl}
            dataFormUrl={dataFormUrl}
            fileListTermsAndConditions={fileListTermsAndConditions}
            fileListForm={fileListForm}
            mode={mode}
          ></UploadFilesComponent>
          {/* </Form.Item> */}
        </Form>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  lab: state.lab,
  // error: state.error,
});

export default connect(mapStateToProps, { loadLab })(
  AccountSettingsFormComponent
);
