import { useEffect, useState } from "react";
import { Form, Input, Row, Col, Select, DatePicker, Divider } from "antd";
import { useSelector } from "react-redux";
import { getPatientCountryCodesList, genderListSelector } from "services/redux/selectors/commonSelectors.js";
import { companyType, country, prefix } from "colors-config";

import dayjs from "dayjs";
import ClinicSelect from "./ClinicSelect";
import { getClinicInfoService } from "api/clinic";

const { Option } = Select;

const AddPatientForm = ({
  addPatientForm,
  onPatientSubmit,
  associatedClinics,
  touched,
  setTouched,
  IsEdit,
  patientInfo,
}) => {
  const patientCountriesList = useSelector(getPatientCountryCodesList);
  const genderList = useSelector(genderListSelector);
  const [clinicianId, setClinicianId] = useState();
  const [clinics, setClinics] = useState([]);

  const [genderValue, setGenderValue] = useState("");

  useEffect(() => {
    let formValues = {};
    if (IsEdit) {
      formValues = {
        firstName: patientInfo?.patient_first_name,
        Email: patientInfo?.patient_email,
        lastName: patientInfo?.patient_last_name,
        phone: patientInfo?.patient_phone_number,
        prefix: patientInfo?.patient_country_code_name,
        Address: patientInfo?.patient_address,
        city: patientInfo?.patient_city,
        county: patientInfo?.patient_county,
        Zipcode: patientInfo?.patient_postal_code,
        country: patientInfo?.patient_country,
        dob: patientInfo?.patient_date_of_birth
          ? dayjs(patientInfo?.patient_date_of_birth)
          : null,
          gender: patientInfo?.gender,
        genderSelfDescribe: patientInfo?.genderSelfDescribe,

      };
      setGenderValue(patientInfo?.gender)
    } else {
      formValues = {
        prefix: prefix,
        country: country,
      };
    }

    addPatientForm.setFieldsValue(formValues);
  }, [IsEdit, patientInfo, addPatientForm]);

  useEffect(() => {
    async function fetchClinicDetails() {
      const clinicDetails = await getClinicInfoService(clinicianId);
      const details = clinicDetails?.data?.body?.multipleAdresses;
      // const detailsList = [];
      const detailsList = details.map(cl => {
        return {
          value: cl.id,
          label: cl.label,
        };
      });
      setClinics(detailsList);
    }
    if (clinicianId) {
      fetchClinicDetails();
    }
  }, [clinicianId]);

  const disabledDate = current => {
    // Disable dates from today and onwards
    return current && current >= dayjs().endOf("day");
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        dropdownClassName="country-dropdown"
        className="country-select"
        size="large"
        dropdownStyle={{ width: "300px" }}
        style={{ width: 110 }}
        labelRender={props => {
          return props.label;
        }}
        options={patientCountriesList?.map((code, index) => {
          return {
            ...code,
            key: index,
            value: code.countryCallingCodes,
            title: `${code.emoji} ${code.countryCallingCodes}`,
            label: code.countryCallingCodes,
          };
        })}
        optionRender={option => {
          const { data } = option;
          return (
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}
            >
              <span className="emoji-font">{data.emoji}</span>
              <span>{data.countryCallingCodes}</span>
              <span>| {data.name}</span>
            </div>
          );
        }}
        showSearch
        filterOption={(input, option) => {
          const lowerCasedInput = input.toLowerCase();
          return (
            option.value.toLowerCase().includes(lowerCasedInput) ||
            option.name.toLowerCase().includes(lowerCasedInput)
          );
        }}
      />
    </Form.Item>
  );

  const onClinicianChange = item => {
    setClinicianId(item.value);
  };

  const onGenderChange = (value)=>{
    setGenderValue(value);
  }

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Form
        form={addPatientForm}
        layout="vertical"
        name="form_in_modal"
        className="addPatient-form"
        onFinish={onPatientSubmit}
        onFieldsChange={(changedValue, allFields) => {
          setTouched(true);
        }}
      >
        {!IsEdit && (
          <>
            <Row gutter={[16, 16]}>
              <Col lg={12}>
                <div className="sectionInputType">
                  <ClinicSelect
                    onClinicianChange={onClinicianChange}
                    clinics={associatedClinics}
                  />
                </div>
              </Col>
              <Col lg={12}>
                <Form.Item
                  className="labelClass"
                  label="Clinic Name"
                  name="addressId"
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Enter Clinic"
                    labelRender={props => {
                      return props.label;
                    }}
                    options={clinics}
                    filterOption={(input, option) => {
                      const lowerCasedInput = input.toLowerCase();
                      return option.label
                        .toLowerCase()
                        .includes(lowerCasedInput);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={12}>
                <div className="sectionInputType">
                  <Form.Item
                    name="patientId"
                    label="Patient ID"
                    rules={[
                      {
                        pattern: new RegExp("^[0-9-_]+$"),
                        message: "Only numeric, _ and - is allowed",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="inputTypeClass"
                      placeholder="Enter Patient ID"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Divider />
          </>
        )}

        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <div className="sectionInputType">
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "First Name is required",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="inputTypeClass"
                  placeholder="Enter first name"
                />
              </Form.Item>
            </div>
          </Col>
          <Col lg={12}>
            <Form.Item
              className="inputTypeClass"
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Last Name is required",
                },
              ]}
            >
              <Input
                size="large"
                className="inputTypeClass"
                placeholder="Enter last name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item
              className="inputTypeClass"
              name="Email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "Invalid email address",
                },
              ]}
            >
              <Input
                disabled={IsEdit}
                size="large"
                placeholder="Email address"
                className="inputTypeClass"
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              className="inputTypeClass"
              name="phone"
              label="Phone Number"
              rules={[
                () => ({
                  validator(_, value) {
                    const convertedValue = Number(value);
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (!/^\d+$/.test(value)) {
                      return Promise.reject("Phone number must contain only numeric characters.");
                    }
            
                    if (!isNaN(convertedValue)) {
                      if (value.length < 6) {
                        return Promise.reject(
                          "Please input a valid phone number with a minimum of 6 digits"
                        );
                      }
                      if (value.length > 15) {
                        return Promise.reject(
                          "Please enter a valid phone number with no more than 15 digits."
                        );
                      }
                      return Promise.resolve();
                    }
            
                    return Promise.reject("Input must be a valid number.");
                  },
                }),
              ]}
            >
              <Input
                size="large"
                addonBefore={prefixSelector}
                className="inputTypeClass"
                style={{
                  width: "100%",
                }}
                placeholder="Phone Number"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <div className="sectionInputType">
              <Form.Item
                className="labelClass"
                name="dob"
                label="Date of Birth"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  className="inputTypeClass dob-input"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
          </Col>
          <Col lg={12}>
            <Form.Item className="labelClass" name="gender" label="Gender">
              <Select
                showSearch
                size="large"
                placeholder="Select Gender"
                onChange={onGenderChange}
                options={genderList.map((gender, index) => {
                  return {
                    key: index,
                    value: gender.value,
                    label: gender.label,
                  };
                })}
                filterOption={(input, option) => {
                  const lowerCasedInput = input.toLowerCase();
                  return option.label
                    .toLowerCase()
                    .includes(lowerCasedInput);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {genderValue === 'PREFER_SELF_DESCRIBE' ?
          <Row gutter={[16, 16]}>
            <Col lg={24}>
              <Form.Item name="genderSelfDescribe" label="Prefer to Self Describe"
                 rules={[
                  {
                    required: true,
                    message: "Please provide a self-description",
                  },
                  {
                    max: 50,
                    message: "Description must be 50 characters or fewer",
                  },
                  {
                    pattern: /^[^0-9]*$/,
                    message: "Description cannot contain numbers",
                  },
                ]}
              >
                <Input size="large"

                />
              </Form.Item>
            </Col>
          </Row> : null
        }


               

        <Row gutter={[16, 16]}>
          <Col lg={24}>
            <Form.Item className="labelClass" name="Address" label="Address">
              <Input
                size="large"
                className="inputTypeClass"
                placeholder="Enter address"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item className="labelClass" name="city" label="City">
              <Input
                size="large"
                className="inputTypeClass"
                placeholder="Enter city"
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              className="labelClass"
              name="county"
              label="County/Province/State"
            >
              <Input
                size="large"
                className="inputTypeClass"
                placeholder="Enter county/province/state"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Form.Item className="labelClass" name="country" label="Country">
              <Select
                showSearch
                size="large"
                placeholder="Enter Country"
                defaultValue={country}
                options={patientCountriesList.map((country, i) => {
                  return {
                    ...country,
                    value: country.name,
                    title: country.name,
                    label: (
                      <>
                        <span className="emoji-font">{country.emoji}</span>
                        {` ${country.name}`}
                      </>
                    ),
                    key: i,
                  };
                })}
                labelRender={props => {
                  return props.title;
                }}
                filterOption={(input, option) => {
                  const lowerCasedInput = input.toLowerCase();
                  return option.name.toLowerCase().includes(lowerCasedInput);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              className="labelClass"
              name="Zipcode"
              label="Post/Zip/EIR Code"
              //       rules={[
              //   { validator: isNumber, message: 'Please enter only numbers.' },
              // ]}
            >
              <Input
                size="large"
                className="inputTypeClass"
                placeholder="Enter zip code "
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddPatientForm;
