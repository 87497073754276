import { useState, useEffect } from "react";
import { Form, Select, Row, Radio, Col, Input } from "antd";
import { memo } from "react";

import { FORM_CONSTANTS } from "../../constants";
import { companyType } from "colors-config";
import { getTreatmentTypes } from "api/clinic";
import { useSelector } from "react-redux";

import AppToolTip from "components/shared/AppToolTip/AppToolTip";
import { getPhasingInfo } from "messages/messageController";
import AppInfoIcon from "components/shared/AppInfoIcon/AppInfoIcon";
const { Option } = Select;
const {TextArea} = Input

const SmileAlignTreatmentType = ({ onFormChange, disabled, showPrescriptionPhasing, treatmentOptions }) => {
  // const [treatmentOptions, setTreatmentOptions] = useState([]);
  // const labDetails = useSelector(state=>state.lab);
  // const getPrescriptionTreatmentTypes = async () => {
  //   try {
  //     const treatmentRes = await getTreatmentTypes(labDetails.labId);
  //     if (treatmentRes?.data?.data) {
  //       setTreatmentOptions(treatmentRes?.data?.data);
  //     }
  //   } catch (error) {
  //     //   message.error("Some Error occured!");
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   if(labDetails.labId){
  //     getPrescriptionTreatmentTypes();
  //   }
  // }, [labDetails]);

  return (
    <>
      <h1 className="form-heading" style={{ margin: "0px 0px 10px 0px" }}>
        1. Treatment Types
      </h1>
      <div className="treatment-row aligner-row" style={{ width: "70%" }}>
        <Form.Item
          name="smilelignTreatmentTypeId"
          label="Treatment Type"
          rules={[
            {
              required: true,
              message: "Please select an Option",
            },
          ]}
        >
          <Select
            disabled={disabled}
            size="large"
            className="form-select"
            placeholder="Select Treatment Type"
            notFoundContent={<p>No treatment type added in the clinic</p>}
            onChange={(value) =>
              onFormChange("smilelignTreatmentTypeId", value)
            }
          >
            {treatmentOptions?.map((item, i) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.treatmentName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="smilelignArches"
          label="Arches treated"
          rules={[
            {
              required: true,
              message: "Please select an Option",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => onFormChange("smilelignArches", e.target.value)}
            disabled={disabled}
          >
            <Radio value={FORM_CONSTANTS.UPPER_AND_LOWER}>
              Upper and Lower
            </Radio>
            <Radio value={FORM_CONSTANTS.UPPER}>Upper</Radio>
            <Radio value={FORM_CONSTANTS.LOWER}>Lower</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      {
        companyType === 5 ?
          <div className="phasing-row" style={{ paddingTop: "30px" }}>
            <Row gutter={[16, 16]}>
              <Col lg={7}>
                <div className="label">
                  <label>
                    <span style={{ color: "#ff4d4f" }}>*</span>Phasing
                    <AppToolTip title={getPhasingInfo()} iconStyles={{ marginLeft: "10px" }}>
                      {/* <AppInfoIcon  /> */}
                    </AppToolTip>
                  </label>
                </div>
              </Col>
              <Col lg={16}>
                <Form.Item
                  name="phasing"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please select an Option",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => onFormChange("phasing", e.target.value)}
                    disabled={disabled}
                  >
                    <Radio value={FORM_CONSTANTS.YES}>Yes</Radio>
                    <Radio value={FORM_CONSTANTS.NO}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {showPrescriptionPhasing && (
              <Row gutter={[16, 16]}>
                <Col lg={24}>
                  <Form.Item name="prescriptionPhasing" label="">
                    <TextArea
                      placeholder="Enter details"
                      rows={4}
                      onChange={(e) =>
                        onFormChange("prescriptionPhasing", e.target.value)
                      }
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
          : null
      }
    </>
  );
};

export default memo(SmileAlignTreatmentType);
