import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Divider, Spin, Popconfirm, message } from "antd";
import classNames from "classnames";
import styles from "./order.module.scss";
import { primaryColor } from "colors-config";
import ScanFileIcon from "components/shared/Icon/ScanFileIcon";

import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import FileViewModal from "components/shared/FileViewModal/FileViewModal";
import { getScanName } from "utils/order";
import { downloadS3File } from "api/patient";

const ScanHistory = ({
  showHistoryModal,
  setShowHistoryModal,
  uploadedFiles,
}) => {
  const [showIframeModal, setShowIframeModal] = useState(false);
  const [iframeFile, setIframeFile] = useState(null);

  const closeModal = () => setShowHistoryModal(false);

  const downloadHandler = file => {
    window.open(downloadS3File(file?.name));
  };

  const fileClickHandler = file => {
    setIframeFile(file);
    setShowIframeModal(true);
  };

  const handleOk = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  const handleCancel = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  return (
    <Modal
      title="Scan Files"
      visible={showHistoryModal}
      onCancel={closeModal}
      onOk={closeModal}
      okText="Submit"
      width={750}
      footer={null}
    >
      <div className={classNames(styles["scan-history-container"])}>
        <Row gutter={16} style={{ minHeight: "300px" }}>
          {uploadedFiles?.map((file, index) => (
            <Col lg={6} key={index}>
                <p className={classNames(styles["scan-name"])}>{getScanName(file.imageView)}</p>
              <div className={classNames(styles["scan-file-display"])}>
                <ScanFileIcon
                  style={{ color: primaryColor }}
                  className={classNames(styles["scan-file-icon"])}
                />
                <p className={classNames(styles["fileName"])}>{file.name}</p>
                <div className={classNames(styles["fileMask"])}>
                  <DownloadOutlined
                    className={styles.downIcon}
                    style={{ margin: "0.5rem", fontSize: "20px" }}
                    onClick={() => downloadHandler(file)}
                  />
                  <EyeOutlined
                    className={styles.viewIcon}
                    onClick={() => fileClickHandler(file)}
                    style={{ fontSize: "20px" }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      {showIframeModal && (
        <FileViewModal
          isModalVisible={showIframeModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          iframeFile={iframeFile}
        />
      )}
    </Modal>
  );
};

export default ScanHistory;
