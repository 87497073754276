import { ReactComponent as DownloadIcon } from "assets/images/download.svg";
import ReactToPrint from "react-to-print";
import { primaryColor } from "colors-config";
import { Select } from "antd";
import { useReactToPrint } from "react-to-print";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import AppButton from "components/shared/AppButton/AppButton";

const { Option } = Select;

const Header = ({
  formData,
  patientInfoState,
  printFormRef,
  selectedVersion,
  onVersionChange,
  setOpenPrescriptionModal,
  companyType,
  prescriptionPdfRef,
}) => {

  const onEditPrescription = () => {
    setOpenPrescriptionModal(true);
  };

  const handleDownload = useReactToPrint({
    options: {
      unit: "in",
      format: [8.5, 11], // Letter size
      orientation: "portrait",
      margin: 1, // Set 1 inch margin on all sides
    },
    pageStyle: "@page { margin: 10mm; }",
    content: () => {
      document.querySelectorAll(".print-part-pdf").forEach(function (container) {
        if (container) {
          container.style.display = "block";
        }
      });
      return prescriptionPdfRef.current;
    },
    onAfterPrint: () => {
      document.querySelectorAll(".print-part-pdf").forEach(function (container) {
        if (container) {
          container.style.display = "none";
        }
      });
    },
  });

  return (
    <div className="prescription-header">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1>Prescription</h1>
        <Select
          disabled={!selectedVersion}
          value={selectedVersion}
          onChange={onVersionChange}
          placeholder="No Previous Version"
          notFoundContent={<p>No Previous Version</p>}
          style={{ margin: "0 14px", width: "140px", textAlign: "left" }}
        >
          {formData?.prescriptionVersions?.map(version => {
            return (
              <Option value={version.value} key={version.value}>
                {version.label}
              </Option>
            );
          })}
        </Select>
        <span style={{ marginRight: "10px" }} className="version-date">
          Updated By {formData?.updatedFrom}:
        </span>
        <span className="version-date">
          {formData?.createdAt
            ? dayjs(formData?.createdAt).format("DD-MMM-YYYY")
            : ""}
        </span>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end",alignItems:'center', gap:"15px" }}>
        <AppButton 
          type='primary'
          onClick={onEditPrescription}
        >Edit</AppButton>

        {/* <ReactToPrint
          trigger={() => {
            return (
              <a>
                <DownloadIcon
                  stroke={primaryColor}
                  // style={{ fill: primaryColor }}
                />
              </a>
            );
          }}
          content={() => printFormRef.current}
        /> */}
        <div
          // onClick={() => _getPrescriptionPdf(formData.prescription_id)}
          onClick={() => handleDownload()}
          style={{
            color: primaryColor,
            cursor: "pointer",
            // marginLeft: "15px",
          }}
        >
          Download
        </div>
      </div>
    </div>
  );
};

export default Header;
