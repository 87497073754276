import React from "react";
import { Row, Col } from "antd";
import CustomScanUpload from "../ScanUpload/CustomScanUpload";

const ScansView = ({
  uploadedFiles,
  treatmentType,
  handleFileUpload,
  handleRemove,
  setFileLoader,
  clinicId,
  patientId,
  setUploadedFiles,
  setScansInfo,
  uploadConfirm
}) => {
  return (
    <Row gutter={16}>
      {Object.entries(uploadedFiles).map(([scanKey, fileData]) => (
        <Col span={6} key={scanKey}>
          <CustomScanUpload
            scanType={scanKey} // e.g., "UpperArch", "LowerArch"
            scanLabel={scanKey}
            treatmentName={treatmentType}
            uploadedFile={fileData} // fileData is the data for that specific scan type or null
            onFileUpload={handleFileUpload}
            onFileRemove={handleRemove}
            prescriptionUpload={true}
            setFileLoader={setFileLoader}
            clinicId={clinicId}
            patientId={patientId}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            setScansInfo={setScansInfo}
            uploadConfirm={uploadConfirm}
          />
        </Col>
      ))}
    </Row>
  );
};

export default ScansView;
