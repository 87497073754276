import { useState, useEffect, useCallback } from "react";
import { message, Modal, Typography, Button, Input } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import GenericButton from "components/shared/buttons/genericButton";
import DeclineModal from "components/shared/modals/declineModal";
import ConfirmationModal from "components/shared/modals/confirmationModal";
import PartnerHeader from "./Components/Header/PartnerHeader";
import { labApiTasks } from "models/lab";
import { getLabId } from "services/redux/selectors/commonSelectors";
import {
  createPartnersTableData,
  getClinicTime,
  getQueryParamFilterStatus,
  onBoardingMapper,
} from "./utils";
import AddClinicModal from "./Components/Modals/AddClinicModel";
import dayjs from "dayjs";
import {
  getPartnerColumns,
  getPartnersRequestColumns,
  getOnBoardingTableColumns,
} from "./Components/PartnerTables/TableColumns";
import PartnerTables from "./Components/PartnerTables/PartnerTables";
import { deleteOnBoardingClinic, getOnBoardingClinics, getPartneredClinics } from "api/lab";
import { PARTNER_TABS, labPartnerStatusConstants, onBoardingConstants } from "../../../constants";
import BulkInvitesTable from "./Components/BulkInvitesTable/BulkInvitesTable";

const { Title } = Typography;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function LabPartners() {
  const query = useQuery();
  const queryParamStatus = query.get("status");
  const history = useHistory();
  const labId = useSelector(getLabId);
  const [isTableSpinning, setIsTableSpinning] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [selectedClinicId, setSelectedClinicId] = useState(null);
  const [selectedClinicName, setSelectedClinicName] = useState(null);
  const [showClinicModal, setShowClinicModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [onBoardingClinics, setOnBoardingClinics] = useState([]);
  const [resendClinic, setResendClinic] = useState(null);
  const [showCsvUpload, setShowCsvUpload] = useState(false);
  const [filteredCount, setFilteredCount] = useState(null);
  const [inviteActionType, setInviteActionType] = useState(null);
  const [showRegistrationLinkModal, setShowRegistraionLinkModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [onBoardingStatusFilter, setOnBoardingStatusFilters] = useState(getQueryParamFilterStatus(queryParamStatus));
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [fromDashboardRun, setFromDashboardRun] = useState(1);
  const [tableSorting, setTableSorting] = useState(null);
  const [onPartnerStatusFilter, setOnPartnerStatusFilters] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 50,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 20, 50],
    total:0
  });

  const [tableFilters, setTableFilters] = useState([]);
  const createRequests = useCallback((pendingClinics) => {
    return pendingClinics.map((dataObj, index) => {
      return {
        key: index,
        requests: partnershipConfirmationButtons(dataObj.clinic_id, dataObj.clinic_name),
        clinicName: dataObj.clinic_name,
        email: dataObj.clinic_email,
        mobile: dataObj.country_code_name + " " + dataObj.clinic_contact,
        hours: getClinicTime(dataObj),
      };
    });
  }, []);
  

  const fetchData = useCallback(
    async (pagination, onBoardingFilter) => {
      if (!labId) {
        return;
      }
      

      let queryParams = {
        labId: labId,
        search: searchKey ? searchKey : null,
        page: tablePagination.current,
        pageSize: tablePagination.pageSize,
      };
      setIsTableSpinning(true);

      

      if (pagination) {
        queryParams = {
          ...queryParams,
          page: pagination.current,
          pageSize: pagination.pageSize,
        };
      }
      
      const filters = onBoardingStatusFilter;
      if (filters && filters.length) {
        queryParams = {
          ...queryParams,
          partnershipStatus: filters.toString(),
        };
      }

      if (queryParamStatus && fromDashboardRun === 1) {
        let storedDates = localStorage.getItem("dashboardFilter");
        if (storedDates) {
          storedDates = JSON.parse(storedDates).map((date) => dayjs(date));
          queryParams.startDate = storedDates[0].format("YYYY-MM-DD");
          queryParams.endDate = storedDates[1].format("YYYY-MM-DD");
          setStartDateFilter(queryParams.startDate);
          setEndDateFilter(queryParams.endDate);
          setFromDashboardRun(2);
        }
      }

      if (startDateFilter && endDateFilter) {
        queryParams.startDate = startDateFilter;
        queryParams.endDate = endDateFilter;
      }
      if (tableSorting) {
        queryParams.sortName = tableSorting.name;
        queryParams.sortType = tableSorting.type;
      }
      try {
        const response = await getOnBoardingClinics(queryParams);
        setOnBoardingClinics(response.data.items);
        if (response) {
          const tableItems = response.data.items;
          const totalRecords = response.data.totalItems;
          const responseData = onBoardingMapper(tableItems);
          setTableData(responseData);

          setTablePagination((prev) => {
            return {
              ...prev,
              total: totalRecords,
            };
          });

        }
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setIsTableSpinning(false);
      }
    },
    [
      searchKey,
      labId,
      queryParamStatus,
      createRequests,
      fromDashboardRun,
      startDateFilter,
      endDateFilter,
      tableSorting,
      onBoardingStatusFilter,
    ]
  );

  // useEffect(() => {
  //   if (queryParamStatus) {
  //     setOnBoardingStatusFilters(getQueryParamFilterStatus(queryParamStatus));
  //   }
  // }, [queryParamStatus]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const partnershipConfirmationButtons = (clinicId, clinicName) => {
    return (
      <div style={{ textAlign: "center" }}>
        <GenericButton
          onClick={() => {
            setSelectedClinicId(clinicId);
            setSelectedClinicName(clinicName);
            setShowDeclineModal(true);
          }}
        >
          Decline
        </GenericButton>

        <GenericButton
          type="primary"
          customStyle={{ marginLeft: "1rem" }}
          onClick={() => {
            setSelectedClinicId(clinicId);
            setSelectedClinicName(clinicName);
            setShowConfirmationModal(true);
          }}
        >
          Accept
        </GenericButton>
      </div>
    );
  };


  const confirmPartnershipStatus = async (clinicId, status, name) => {
    message.loading("Updating Status", 0);
    const model = {
      clinic_id: clinicId,
      lab_id: labId,
      status,
    };
    try {
      await labApiTasks.post("lab/editPartnership", model);
      message.destroy(); // Close the loading message
      message.success(`${name} is now a partner`);
      // await _getPending();
      await fetchData();
    } catch (error) {
      message.destroy();
      message.error("Something didn't go right");
    }
  };
  const onSearch = (searchKey) => {
    setTablePagination((prev) => {
      return {
        ...prev,
        current: 1,
      };
    });
    
    searchKey = searchKey.trim();
    setSearchKey(searchKey);
  };

  const onDelete = async (clinicId, data) => {
    setIsTableSpinning(true);
    const apiPayload = {
      labId: labId,
      id: clinicId,
    };
    try {
      const res = await deleteOnBoardingClinic(apiPayload);
      if (res) {
        const boardedClinics = [...data];
        const filteredClinics = boardedClinics.filter((clinic) => clinic.id !== clinicId);
        setOnBoardingClinics(filteredClinics);
        setTableData(filteredClinics);
        message.success("Clinic invite deleted successfully");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsTableSpinning(false);
    }
  };

  const onDeclineConfirmation = () => {
    confirmPartnershipStatus(selectedClinicId, labPartnerStatusConstants.REJECTED);
  };

  const onAcceptConfirmation = () => {
    confirmPartnershipStatus(
      selectedClinicId,
      labPartnerStatusConstants.PARTNERED,
      selectedClinicName
    );
  };


  const onResend = (record) => {
    setResendClinic(record);
    setShowClinicModal(true);
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(generateRegistrationLink())
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateRegistrationLink = () => {
    const jsonString = JSON.stringify({
      labId: labId,
    });
    const base64String = require("buffer").Buffer.from(jsonString).toString("base64");
    return `${process.env.REACT_APP_CLINIC_REGISTRATION_URL}?key=${base64String}`;
  };

  const onTableChange = (pagination, filters, sorter, { action }) => {
    const onBoardingFilter = filters.onboarding_status;
    setTableFilters(onBoardingFilter);
    if (filters.onboarding_status) {
      setOnBoardingStatusFilters(filters.onboarding_status);
    }
    else if (filters.status) {
      setOnPartnerStatusFilters(filters.status); 
    }
  
    else {
      setOnBoardingStatusFilters([]);
      setOnPartnerStatusFilters([]);
    }


    const paginationCopy = {
      ...tablePagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    };
    
    setTablePagination(paginationCopy);
    if(queryParamStatus){
      history.push({
        pathname: "/lab/partners",
        search: "",
      });
    }
  };

  const getTableColumns = (data) => {
      return getOnBoardingTableColumns(
        onResend,
        onDelete,
        data,
        queryParamStatus,
        setStartDateFilter,
        setEndDateFilter,
        startDateFilter,
        endDateFilter,
        setFromDashboardRun,
        onBoardingStatusFilter,
        tableSorting,
        setTableSorting,
        queryParamStatus,
        history
      );
  };

  

  return (
    <div className="partners-container">
      {showClinicModal && (
        <AddClinicModal
          showCsvUpload={showCsvUpload}
          setShowCsvUpload={setShowCsvUpload}
          showClinicModal={showClinicModal}
          setShowClinicModal={setShowClinicModal}
          labId={labId}
          setOnBoardingClinics={setOnBoardingClinics}
          onBoardingClinics={onBoardingClinics}
          tableData={tableData}
          setTableData={setTableData}
          resendClinic={resendClinic}
          setResendClinic={setResendClinic}
          onResend={onResend}
          onDelete={onDelete}
          fetchData={fetchData}
          tablePagination={tablePagination}
          setTablePagination={setTablePagination}
          onBoardingStatusFilter={onBoardingStatusFilter}
        />
      )}
      <PartnerHeader
        showCsvUpload={showCsvUpload}
        setShowCsvUpload={setShowCsvUpload}
        onSearch={onSearch}
        labId={labId}
        totalOnBoarded={filteredCount ? filteredCount : onBoardingClinics?.length}
        setShowClinicModal={setShowClinicModal}
        setInviteActionType={setInviteActionType}
        setShowRegistraionLinkModal={setShowRegistraionLinkModal}
        tablePagination={tablePagination}
      />

      <div style={{ marginLeft: "1rem" }}>
        <PartnerTables
          columns={getTableColumns(tableData)}
          tableData={tableData}
          loading={isTableSpinning}
          // onRow={getOnRow()}
          pagination={tablePagination}
          onChange={onTableChange}
        />
        {showConfirmationModal && (
          <ConfirmationModal
            title="Accept Request"
            okText={<p>Yes, Accept</p>}
            cancelText={<p>Cancel</p>}
            content={`Are you sure of confirming partnership with ${selectedClinicName}`}
            showModal={showConfirmationModal}
            onConfirmation={onAcceptConfirmation}
            setShow={setShowConfirmationModal}
          ></ConfirmationModal>
        )}

        {showDeclineModal && (
          <DeclineModal
            title="Decline Request"
            okText={<p>Yes, Decline</p>}
            cancelText={<p>Cancel</p>}
            content={`Are you sure of declining partnership with ${selectedClinicName}`}
            showModal={showDeclineModal}
            onConfirmation={onDeclineConfirmation}
            setShow={setShowDeclineModal}
          ></DeclineModal>
        )}
        {!!inviteActionType && (
          <BulkInvitesTable
            onBoardingRequestedClinics={onBoardingClinics.filter(
              (data) => data.onboarding_status === "ONBOARDING_REQUESTED"
            )}
            inviteActionType={inviteActionType}
            setInviteActionType={setInviteActionType}
            labId={labId}
            getLabPartners={fetchData}
          />
        )}
        {showRegistrationLinkModal && (
          <Modal
            title={
              <div>
                <Title level={5} style={{ margin: "0px" }}>
                  Copy the Registration Link
                </Title>
              </div>
            }
            open={showRegistrationLinkModal}
            onCancel={() => setShowRegistraionLinkModal(false)}
            footer={null}
          >
            <div>
              <Input
                disabled
                variant="filled"
                defaultValue={generateRegistrationLink}
                addonAfter={
                  <Button onClick={handleCopyClick} shape="round" type="primary">
                    {isCopied ? "Copied!" : "Copy"}
                  </Button>
                }
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default LabPartners;
