import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import styles from './fileView.module.scss';
import { StlViewer } from "react-stl-file-viewer";
import { getSTLUrl } from 'utils/Lab';


const supportedMimeTypes = [
  "application/x-zip-compressed",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "video/webm",
  "video/mp4",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "model/stl"
];

const FileViewModal = ({ isModalVisible, handleOk, handleCancel, iframeFile }) => {
  const [volume, setVolume] = useState(0);

  // Ensure we only proceed if `meta` exists or fallback to another check
  const mimeType = iframeFile?.meta?.mimeType || iframeFile?.type || '';
  const extension = iframeFile?.meta?.extension || '';

  const isSupportedType = supportedMimeTypes.includes(mimeType);
  const isImage = mimeType.startsWith("image/");
  const isPDF = mimeType === "application/pdf";
  const isStl = extension === "stl";

  return (
    <Modal
      title={iframeFile?.name}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      okButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      width={1020}
    >
      <div className={styles.iframeContainer}>
        {isSupportedType && !isPDF && !isStl && (
          <a href={iframeFile?.url} target="_blank" rel="noreferrer">
            <p>{iframeFile?.name}</p>
          </a>
        )}

        {isPDF && (
          <iframe
            title="PDF Viewer"
            frameBorder="0"
            style={{ width: "100%", minHeight: "45rem", border: "none", overflow: "hidden" }}
            src={iframeFile?.url}
          ></iframe>
        )}

        {!isSupportedType && !isImage && !isStl && (
          <div>
           <p>This file type cannot be previewed. Please download it to view.</p>
            <a href={iframeFile?.url} download={iframeFile?.name} target="_blank" rel="noreferrer">
              <Button type="primary" shape="round" >Download File</Button>
            </a>
          </div>
        )}

        {isImage && (
          <img src={iframeFile?.url} alt={iframeFile?.name} style={{ width: '100%', height: 'auto' }} />
        )}

        {isStl && (
          <StlViewer
            width={950}
            height={600}
            url={getSTLUrl(iframeFile, iframeFile.url)}
            groundColor="rgb(255, 255, 255)"
            objectColor="rgb(137, 137, 137)"
            skyboxColor="rgb(255, 255, 255)"
            gridLineColor="rgb(0, 0, 0)"
            lightColor="rgb(255, 255, 255)"
            volume={setVolume}
          />
        )}
      </div>
    </Modal>
  );
};

export default FileViewModal;
