import {
  Switch,
  Route,
  useLocation,
  Redirect,
  HashRouter as Router,
  useHistory,
} from "react-router-dom";
import React, { useEffect } from "react";

import Registration from "./routes/public/registration";
import Login from "./routes/public/login";
import SignUpConfirmation from "./components/private/signUpConfirmation";
import Onboarding from "./routes/private/onboarding";
import Notifications from "./routes/private/notifications";
import LayoutComponent from "./components/private/layoutComponent";
import WelcomePageComponent from "./components/private/welcomePageComponent";
import LabPartners from "routes/private/LabPartners/index";
import Profile from "./routes/private/Settings/Settings";
import PatientDetail from "routes/private/PatientDetails/index";
import PatientList from "./routes/private/PatientList/PatientList";
import ArchivedPatients from "routes/private/ArchivePatients";
import clinicDetails from "./routes/private/clinicDetails";
import InvoiceList from "./routes/private/invoiceList";
import PatientScanUpload from "./routes/private/patientScanUpload";
import ChangePassword from "./routes/private/changePassword";
import ForgotPassword from "./routes/public/forgotPassword";
import {
  changeNotificationState,
  getAllCountriesAndCodes,
  loadLabNotificationCount,
} from "./services/redux/actions/labActions";
import { fetchCountriesAndCodes } from "services/redux/thunks/thunks";
import { onMessageListener } from "./firebase.js";
import { Provider, useDispatch, useSelector } from "react-redux";
// import store from "./services/redux/store";
import { loadUser } from "./services/redux/actions/authActions";
import PrivateRoute from "./hoc/privateRoute";
import { Amplify, Auth } from "aws-amplify";
import awsConfig from "./config/AWSConfig";
import { ConfigProvider } from "antd";
import axiosInstance from "api/api";
import {
  headerColor,
  primaryColor,
  secondaryColor,
  tagBackgroundColor,
  tagTextColor,
  buttonColor,
  companyType,
} from "colors-config";
import "./App.scss";
import SEO from "components/shared/SEO/SEO";
import Resources from "routes/private/resources";
import Dashboard from "routes/private/dashboard";
import { logout } from "services/redux/actions/authActions.js";
import useInactivityLogout from "./CustomHooks/useInactivityHook";
import { userRoleSelector } from "services/redux/selectors/lab.selectors";
import { isAdmin } from "utils/auth";
import { getAllCommonList } from "services/redux/actions/commonActions";

Amplify.configure(awsConfig);

async function getAuthenticationStatus() {
  try {
    await Auth.currentSession();

    return true;
  } catch (error) {
    return false;
  }
}

export function updateNotificationState(dispatch) {
  // store.dispatch(changeNotificationState(true));
  dispatch(loadLabNotificationCount());
}
const Main = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userRole = useSelector(userRoleSelector);

  useInactivityLogout();
  // const location = useLocation();
  // const isFirstLogin = useSelector(getIsFirstLogin);

  const checkTokenValidity = async () => {
    try {
      const session = await Auth.currentSession();
      if (!session) {
        return;
      }
      const accessToken = session.getIdToken();

      const currentTime = Math.floor(Date.now() / 1000);
      const expTime = accessToken.getExpiration();

      if (expTime < currentTime) {
        dispatch(logout());
        history.push("/");
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const responseInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        checkTokenValidity();

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <div className={process.env.REACT_APP_COMPANY_TYPE === "5" ? "App smileAlign-app" : "App"}>
      <SEO />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            let redirectLink = "/login";

            if (getAuthenticationStatus()) {
              redirectLink = "/lab/patients";
            }

            return <Redirect to={redirectLink} />;
          }}
        />

        <Route exact path="/registration" component={Registration} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup-confirmation" component={SignUpConfirmation} />
        {/* <Route
            exact
            path="/calendar"
            component={AppointmentCalendarComponent}
          /> */}
        <Route exact path="/layout" component={LayoutComponent} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        {/* <PrivateRoute  path="/lab/patientPrescription/:patientId" component={PatientPrescription}/> */}
        {/* <Route exact path="/private" component={Private} /> */}
        {/* <AuthRoute
          exact
          path="/private"
          type="private"
          component={Private}
        ></AuthRoute> */}
        <PrivateRoute path="/onboarding" component={Onboarding} />
        {isAdmin(userRole) && <PrivateRoute path="/lab/profile" component={Profile} />}
        <PrivateRoute path="/lab/partners" component={LabPartners} />
        <PrivateRoute path="/changePassword" component={ChangePassword} />

        <PrivateRoute path="/lab/billing" component={InvoiceList} />

        <PrivateRoute path="/lab/resources" component={Resources} />
        <PrivateRoute exact path="/lab/clinic/:clinicId" component={clinicDetails} />
        <PrivateRoute exact path="/lab/patient/:patientId" component={PatientDetail} />
        <PrivateRoute exact path="/lab/patient/:patientId/scan" component={PatientScanUpload} />
        <PrivateRoute exact path="/lab/notifications" component={Notifications} />

        <PrivateRoute path="/welcome-page" component={WelcomePageComponent} />
        <PrivateRoute path="/lab/patients" component={PatientList} />
        <PrivateRoute path="/lab/archivedPatients" component={ArchivedPatients} />
        <PrivateRoute path="/lab/dashboard" component={Dashboard} />
        {/* Catch-all route */}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
};

function App({ location }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCountriesAndCodes());
    dispatch(getAllCommonList());
    dispatch(loadUser());
    dispatch(getAllCountriesAndCodes());
  }, [dispatch]);

  onMessageListener()
    .then((payload) => {
      // console.log(payload, "pat");
      // updateNotificationState()
    })
    .catch((err) => console.log("failed: ", err));

  navigator.serviceWorker.addEventListener("message", (event) => {
    updateNotificationState(dispatch);
  });

  return (
    <Router>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            // colorPrimary: "#00c6e9",
            colorPrimary: primaryColor,
            borderRadius: 4,
            // colorText:"#fff",
          },
          components: {
            Radio: {
              // buttonColor: "#fff",
              buttonSolidCheckedColor: secondaryColor,
              buttonCheckedBg: primaryColor,
              // colorText:"#fff",
            },
            Button: {
              colorTextLightSolid:
                process.env.REACT_APP_COMPANY_TYPE === "4" ? secondaryColor : buttonColor,
            },
            Tag: {
              defaultColor: tagTextColor,
              defaultBg: tagBackgroundColor,
            },
          },
        }}
      >
        <Main />
      </ConfigProvider>
    </Router>
  );
}

export default App;
