import {
  ADD_COMMON_DATA
} from "./types";
import { getCommonListAPI } from "api/common";


export const getAllCommonList = () => async (dispatch, getState) => {
  try {
    const { data } = await getCommonListAPI();
    await dispatch({
      type: ADD_COMMON_DATA,
      payload: data?.data,
    });
  } catch (error) {
    console.error(error);
  }
};
