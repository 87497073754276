import { combineReducers } from "redux";

import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import labReducer from "./labReducer";
import staffReducer from "./staffReducer";
import commonReducer from "./commonReducer";

export default combineReducers({
  error: errorReducer,
  auth: authReducer,
  lab: labReducer,
  staff: staffReducer,
  common: commonReducer
});
