export const GET_ITEMS = "GET_ITEMS";
export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const ITEMS_LOADING = "ITEMS_LOADING";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_TOKEN = "SET_TOKEN";
export const LAB_LOADED = "LAB_LOADED";
export const GET_LOV = "GET_LOV";
export const NOTIFICATION_STATE_CHANGE = "NOTIFICATION_STATE_CHANGE";
export const CLEAR_LAB = "CLEAR_LAB";
export const UPDATE_COMMENT_BOX_STATE = "UPDATE_COMMENT_BOX_STATE";

export const GET_COUNTRIES_CODES = "GET/COUNTRIES_AND_CODES";

export const UPDATE_LAB_DATA = "LAB/UPDATE_DATA";

export const LAB_NOTIFICATION_LOADED = "LAB_NOTIFICATION_LOADED";
export const LAB_NOTIFICATION_READ = "LAB_NOTIFICATION_READ";
export const PATIENT_COUNTRY_CODES = "PATIENT_COUNTRY_CODES";

export const LOAD_STAFF_MEMBERS = "LOAD_STAFF_MEMBERS";

export const LOAD_PATIENT_LIST = "LOAD_PATIENT_LIST";

export const ADD_COMMON_DATA= "ADD_COMMON_DATA"
