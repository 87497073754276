import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Divider, Skeleton } from "antd";
import classNames from "classnames";
import styles from "../../scans.module.scss";
import { primaryColor } from "colors-config";
import ScanFileIcon from "components/shared/Icon/ScanFileIcon";
import {
  getPrescriptionScanFilesHistory,
  deletePrescriptionScanFile,
} from "api/prescription";
import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import FileViewModal from "components/shared/FileViewModal/FileViewModal";
import { downloadS3File } from "api/patient";
import { getScanName } from "utils/order";
import EmptyTableDesign from "components/shared/EmptyTable/EmptyTable";

const ScanHistory = ({
  showHistoryModal,
  setShowHistoryModal,
  patientId,
  clinicId,
}) => {
  const [loading, setLoading] = useState(false);
  const [showIframeModal, setShowIframeModal] = useState(false);
  const [iframeFile, setIframeFile] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([
    // {
    //   type: "Appliances",
    //   updatedBy: "Lab",
    //   date: "09-03-2023",
    //   scans: [
    //     {
    //       name: "filename1.stl",
    //       type: "Upper Arch",
    //     },
    //     {
    //       name: "filename2.stl",
    //       type: "Lower Arch",
    //     },
    //     {
    //       name: "filename1.stl",
    //       type: "Bite Scan",
    //     },
    //     {
    //       name: "filename1.stl",
    //       type: "Bite scan2",
    //     },
    //   ],
    // },
    // {
    //   type: "Prescription",
    //   updatedBy: "Clinic",
    //   date: "09-03-2023",
    //   scans: [
    //     {
    //       name: "filename1.stl",
    //       type: "Upper Arch",
    //     },
    //     {
    //       name: "filename2.stl",
    //       type: "Lower Arch",
    //     },
    //   ],
    // },
  ]);
  useEffect(() => {
    const fetchPrescriptionData = async () => {
      if (patientId) {
        try {
          setLoading(true);
          const { data } = await getPrescriptionScanFilesHistory(patientId);
          setUploadedFiles(data?.data);
        } catch (error) {
          console.error("Error fetching prescription data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPrescriptionData();
  }, [patientId]);

  const closeModal = () => setShowHistoryModal(false);

  const downloadHandler = file => {
    window.open(downloadS3File(file?.name));
  };

  const fileClickHandler = file => {
    setIframeFile(file);
    setShowIframeModal(true);
  };
  const handleOk = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };
  const handleCancel = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  return (
    <Modal
      title="Scan Files History"
      visible={showHistoryModal}
      onCancel={closeModal}
      onOk={closeModal}
      okText="Submit"
      width={750}
      footer={null}
    >
      <Skeleton active loading={loading}>
        {uploadedFiles.length ? (
          <Row gutter={16} style={{ minHeight: "300px" }}>
            <Col span={24}>
              {uploadedFiles.map((file, index) => (
                <div key={index}>
                  <div className={classNames(styles["scan-history-container"])}>
                    <div className={classNames(styles["scan-history-header"])}>
                      <h2
                        style={{ color: primaryColor }}
                      >{`(${file.model})`}</h2>
                      <p>
                        Updated by {file.createdFrom}:{" "}
                        <span>{file.uploadDate}</span>
                      </p>
                    </div>
                    <Row gutter={[32, 32]}>
                      {file?.files?.map((scan, scanIndex) => (
                        <Col lg={6} key={scanIndex}>
                            <p className={classNames(styles["scan-file-name"])}>{getScanName(scan.imageView)}</p>
                          <div
                            className={classNames(styles["scan-file-display"])}
                          >
                            <ScanFileIcon
                              style={{ color: primaryColor }}
                              className={classNames(styles["scan-file-icon"])}
                            />
                            <p className={classNames(styles["fileName"])}>
                              {scan.name}
                            </p>
                            <div className={classNames(styles["fileMask"])}>
                              {/* <Popconfirm
                              title="Delete"
                              description="Are you sure to delete this scan?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => handleFileDelete(scan)}
                            >
                              <DeleteOutlined style={{ fontSize: "20px" }} />
                            </Popconfirm> */}
                              <DownloadOutlined
                                className={styles.downIcon}
                                style={{ margin: "0.5rem", fontSize: "20px" }}
                                onClick={() => downloadHandler(scan)}
                              />
                              <EyeOutlined
                                className={styles.viewIcon}
                                onClick={() => fileClickHandler(scan)}
                                style={{ fontSize: "20px" }}
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <Divider />
                </div>
              ))}
            </Col>
          </Row>
        ) : (
          <>
          <h2 style={{ margin: "0px" }}>No Scans Available Yet</h2>
          <p >Upload scans to view them here.</p>
          <EmptyTableDesign/>
            </>
        )}

        {showIframeModal && (
          <FileViewModal
            isModalVisible={showIframeModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
            iframeFile={iframeFile}
          />
        )}
      </Skeleton>
    </Modal>
  );
};

export default ScanHistory;
