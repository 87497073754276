import { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import DetailInfo from "components/shared/DetailInfo/DetailInfo";
import ScanFileIcon from "components/shared/Icon/ScanFileIcon";
import styles from "./orderDetail.module.scss";
import { orderDetailMapper } from "utils/treatment";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";
import TreatmentProductToolTip from "../TreatmentProductsTable/TreatmentProductToolTip";
import { primaryColor } from "colors-config";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";


const OrderDetail = ({ orderDetails, renderOrderShippingAddress }) => {

  const [orderData, setOrderData] = useState({
    orderNote: "",
    shipping_address: "",
    payment_status: "",
    order_status: "",
    productList: [
      {
        productName: "",
        arches: "",
        currency: "",
        free: "",
        quantity: "",
        price: 0,
      },
    ],
  });

  useEffect(() => {
    const data = orderDetailMapper(orderDetails);
    setOrderData(data);
  }, [orderDetails]);


  return (
    <div className="order-status-container">
      <Row gutter={16} style={{ display: "flex", alignItems: "stretch" }}>
        <Col span={15}>
          <div
            style={{ display: "flex", gap: "5px", flexDirection: "column" }}
            className={styles["border-right"]}
          >
            <Row gutter={[12, 12]} align="top" justify="space-between">
              <Col lg={6}>
                <DetailInfo label="Treatment Name" value={""} />
              </Col>
              <Col lg={5}>
                <DetailInfo label="Arch Treatment" value={""} />
              </Col>
              <Col lg={5}>
                <DetailInfo label="Lab Price per Unit" value={""} />
              </Col>
              <Col lg={2}>
                <DetailInfo label="Quantity" value={""} />
              </Col>
              <Col lg={6}>
                <DetailInfo label="Lab Total Price" value={""} />
              </Col>
            </Row>
            {orderData?.productList?.map(product => {
              return (
                <Row gutter={[12, 12]} align="top" justify="space-between">
                  <Col lg={6}>
                    <DetailInfo label="" value={product?.productName} />
                
                  </Col>
                  <Col lg={5}>
                    <DetailInfo label="" value={product?.arches} />
                  </Col>
                  <Col lg={5}>
                    <Row>
                      <Col>
                        <DetailInfo label="" value={product.price} />
                      </Col>
                      <Col>
                        {product.discountGiven && (
                          <AppToolTip
                            title={
                              <TreatmentProductToolTip
                                currency={product.currency}
                                price={product.productPrice}
                                netPrice={product.netPrice}
                                discountPrice={product.discountPrice}
                              />
                            }
                            iconStyles={{ marginLeft: "10px" }}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={2}>
                    <DetailInfo label="" value={product?.quantity} />
                  </Col>
                  <Col lg={6}>
                    <DetailInfo label="" value={product.total} />
                  </Col>
                </Row>
              );
            })}
            <Row gutter={[12, 12]} align="top" justify="space-between">
              <Col lg={2}>
                {/* {orderData?.orderScanFiles?.length > 0 && (
                  <Button type="primary" shape="round">
                    Order Scans
                  </Button>
                )} */}
              </Col>
              <Col lg={6} offset={16}>
                <div className={styles["border-top"]} />
                <div className={styles["total"]}>
                  <DetailInfo
                    label=""
                    value={orderData?.totalPrice}
                    bold={true}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={4}>
          <div className={styles["border-right"]}>
            <Row gutter={[12, 12]} style={{ height: "100%" }}>
              <Col lg={24}>
                <DetailInfo
                  label="Payment Status"
                  value={orderData?.payment_status}
                />
              </Col>
              <Col lg={24}>
                <DetailInfo
                  label="Order Status"
                  value={orderData?.order_status}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={5}>
          <Row gutter={[12, 12]}>
            <Col lg={24}>
              <DetailInfo
                label={
                  <span>
                    Ship To :{" "}
                    <span style={{ fontWeight: "bold", color: "#77888b" }}>
                      {orderDetails?.shipping_address == "clinic"
                        ? orderDetails?.clinic_name
                        : "Patient"}
                    </span>
                  </span>
                }
                value={
                  <span>
                    {orderData.shipping_address}
                    {renderOrderShippingAddress()}
                  </span>
                }
              />
            </Col>
            <Col lg={24}>
              <DetailInfo label="Order Note" value={orderData?.orderNote} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OrderDetail;
