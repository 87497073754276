import { Col, Row } from "antd";
import { companyType } from "colors-config";
import FileList from "components/private/IPRDetail/FileList/FileList";
import TreatmentProductsTable from "components/private/TreatmentProductsTable/TreatmentProductsTable";
import { PRODUCT_TYPE_PACKAGE } from "constants";
import { checkTextEditorContent } from "utils/Lab";

const TreatmentAligners = ({ treatment }) => {
  // console.log("treatmenttreatmenttreatment", treatment)
  const loadAdditionalDetails = () => {
    const hasContent = checkTextEditorContent(treatment?.additionalDetails || "");
    return (
      <div
        className={`patient-response ${hasContent ? "detail-container" : ""}`}
        style={{ fontWeight: "400", textAlign: "justify" }}
      >
        {hasContent ? (
          <div
            dangerouslySetInnerHTML={{
              __html: treatment?.additionalDetails,
            }}
          />
        ) : (
          "--"
        )}
      </div>
    );
  };

  return (
    <Row className="aligner-component-container">
      <div className="aligner-component">
        <Row gutter={4}>
          <Col span={8}>
            <div>
              <Row>
                <p className="patient-question">
                  No. of Upper {companyType === 4 ? "Stages" : "Aligners"}
                </p>
              </Row>
              <Row>
                <p className="patient-response">{treatment?.num_of_upper_aligners}</p>
              </Row>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <Row>
                <p className="patient-question">
                  No. of Lower {companyType === 4 ? "Stages" : "Aligners"}
                </p>
              </Row>
              <Row>
                <p className="patient-response">{treatment?.num_of_lower_aligners}</p>
              </Row>
            </div>
          </Col>
          {/* <Col span={8}>
            <div>
              <Row>
                <p className="patient-question">Attachments</p>
              </Row>
              <Row>
                <p className="patient-response">{Boolean(treatment?.attachments) === true ? "Yes" : "No"}</p>
              </Row>
            </div>
          </Col> */}
        </Row>
        <TreatmentProductsTable treatment={treatment}/>
        {/* <>
          <Row gutter={4}>
              <Col span={8}>
              <div>
                <Row>
                  <p className="patient-question">Lab Product/Service Name</p>
                </Row>
                <Row>
                  <p className="patient-response">
                    {treatment?.labProductData ? treatment?.labProductData?.productName : "--"}
                  </p>
                </Row>
              </div>
            </Col>
            {
             treatment?.labProductData?.quantityType !== PRODUCT_TYPE_PACKAGE  &&(
              <Col span={8}>
                <div>
                <Row>
                  <p className="patient-question">Quantity</p>
                </Row>
                <Row>
                  <p className="patient-response">
                    {treatment?.quantity && treatment?.labProductData?.productName
                      ? treatment?.quantity
                      : !treatment.quantity && treatment?.labProductData?.quantityType === "Package"
                      ? "1"
                      : "--"}
                  </p>
                </Row>
              </div>
            </Col>
             )
          }
          

            {treatment?.labProductData?.quantityType == "Per aligner" ? (
              <Col span={4}>
                <div>
                  <Row>
                    <p style={{ textAlign: "left" }} className="patient-question">
                      Lab Price per Unit
                    </p>
                  </Row>
                  <Row>
                    <p className="patient-response">
                      {treatment?.labProductData?.free
                        ? `${treatment?.labProductData?.currency} 0.00 (Free)`
                        : treatment?.labProductData
                        ? treatment?.labProductData?.currency +
                          " " +
                          treatment?.labProductData?.price.toFixed(2)
                        : "--"}
                    </p>
                  </Row>
                </div>
              </Col>
            ) : null}

            <Col span={4}>
              <div>
                <Row>
                  <p style={{ textAlign: "left" }} className="patient-question">
                    Lab Total Price
                  </p>
                </Row>
                <Row>
                  <p className="patient-response">
                    {treatment?.labProductData?.free
                      ? `${treatment?.labProductData?.currency} 0.00 (Free)`
                      : treatment?.labProductData
                      ? treatment?.labProductData?.currency + " " + getTotalPrice()
                      : "--"}
              
                  </p>
                </Row>
              </div>
            </Col>
          </Row>
          <Row gutter={4}>
            <Col span={8}>
              <div>
                <Row>
                  <p className="patient-question">Arches Treated</p>
                </Row>
                <Row>
                  <p className="patient-response">
                    {treatment?.labProductData?.arches ? treatment?.labProductData?.arches : "--"}
                  </p>
                </Row>
              </div>
            </Col>
        
          </Row>
        </> */}
        <div>
          {companyType === 5 && (
            <Row justify="start">
              <Col md={24}>
                <p className="patient-question" style={{ textAlign: "left" }}>
                  Phasing
                </p>
              </Col>
              <Col md={24}>
                <div
                  style={{
                    textAlign: "left",
                    marginBottom: 10,
                    fontWeight: "600",
                  }}
                >
                  {treatment?.phasing || "--"}
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <p className="patient-question">Additional Details</p>
          </Row>
          <Row>{loadAdditionalDetails()}</Row>
          <Row>
            <p className="patient-question" style={{marginTop:"10px"}}>Manual Treatment Guide PDF</p>

          </Row>
          <Row>
            <FileList
              iprFile={treatment?.iprDocument}
            />
          </Row>
        </div>
      </div>
    </Row>
  );
};

export default TreatmentAligners;
