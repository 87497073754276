import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  Tag,
  Input,
  Row,
  Col,
  Typography,
  Radio,
  Tooltip,
  Dropdown,
  Menu,
  message,
} from "antd";
import moment from "moment";

import "assets/styles/components/patientListComponent.scss";

import { Empty } from "antd";

import { useHistory, Link } from "react-router-dom";

import FilterDataRangePicker from "components/shared/filterDataRangePicker";

import { connect, useSelector } from "react-redux";
import SLA_COLORS from "utils/slaColors";

import { MoreOutlined, DeleteOutlined } from "@ant-design/icons";

import { ShowTotalNumber } from "components/shared/ShowTotalNumber/ShowTotalNumber";
import ClinicNameFilter from "components/private/ClinicNameFilter/ClinicNameFilter";
import { showConfirmationModal } from "components/shared/ShowConfirmModal/index";
import { getDurationStatusFilter, getOrderStatusFilter } from "utils/Lab";
import { subUserSelector } from "services/redux/selectors/lab.selectors";
import { columnTitleElement } from "utils/commonMethod";
import Assignee from "./TableCells/Assignee";
import { USER_TABS } from "../constants";
import { primaryColor } from "colors-config";
import { archivePatient } from "api/lab";
import dayjs from "dayjs";
import AppTag from "components/shared/AppTag/AppTag";

// import { SELECTION_NONE } from "antd/lib/table/hooks/useSelection";

const { Search } = Input;
const { Title } = Typography;

const PatientListComponent = ({
  lab,
  patientsList,
  paginationLimit,
  paginationPage,
  onSearch,
  totalRecords,
  handleDateFilter,
  onPagination,
  handleDateOrderedFilter,
  statusParams,
  setTotalRecords,
  fromDate,
  endDate,
  fromDateOrdered,
  endDateOrdered,
  staffMembers,
  handlePatientAssignee,
  getPatients
}) => {
  const subUser = useSelector(subUserSelector);
  const [tableData, setTableData] = useState([]);
  const [paginationObj, setPaginationObj] = useState({});
  const history = useHistory();
  const [patientsFilterData, setPatientsFilterData] = useState([]);
  const [patientTab, setPatientTab] = useState(USER_TABS.ALL);
  const [assigneeFilterValues, setAssigneeFilterValues] = useState([]);

  useEffect(() => {
    if (patientsList?.length >= 0) {
      setTableData(createTableData());
      setPatientsFilterData(createTableData());
    }
  }, [patientsList]);

  useEffect(() => {
    const obj = {
      current: paginationPage,
      pageSize: paginationLimit,
      showSizeChanger: true,
      // defaultCurrent: 1,
      // defaultPageSize: 50,
      pageSizeOptions: [5, 10, 20, 50],
      // size: "small",
      total: totalRecords,
    };

    setPaginationObj(obj);
  }, [paginationPage, paginationLimit, totalRecords, onSearch]);

  const createTableData = () => {
    return patientsList.map((dataObj, index) => {
      return {
        key: index,
        // dateCreated: dataObj.product_order_date
        //   ? moment(dataObj.product_order_date).format("DD/MM/YYYY")
        //   : "Not Available",
        staffId: dataObj.staffId,
        staffPatientId: dataObj.staffPatientId,
        dateCreated: dataObj.patientCreated
          ? moment(dataObj.patientCreated).format("DD/MM/YYYY")
          : "Not Available",
        patientDetails: {
          patientID: dataObj.patient_id,
          patientName: dataObj?.patient_name ? dataObj?.patient_name : "",
        },
        clinicianDetails: {
          clinicName: dataObj.clinic_name ? dataObj.clinic_name : "Not Available",
          clinicLabel: dataObj.clinicLabel ? dataObj.clinicLabel : "",
        },
        serviceType: dataObj.product_name ? dataObj.product_name : "Not Available",
        orderStatus: dataObj.status_name ? dataObj.status_name : "Not Available",
        dateOrdered: dataObj.dateOrdered ? moment(dataObj.dateOrdered).format("DD/MM/YYYY") : "-",
        sla: dataObj.sla,
      };
    });
  };

  const getClinicNames = () => {
    const names = [...new Set(tableData?.map((data) => data.clinicName))];
    let data = [];
    names.forEach((value) => {
      data.push({
        text: value,
        value: value,
      });
    });
    return data;
  };

  const onArchive = async (patientDetails) => {
    try {
      await archivePatient(patientDetails);
      await getPatients();
      message.success("Patient Archived Successfully");
    } catch (e) {
      message.error("Failed to archive patient. Please try again later");
    }
  };

  const renderDropdownMenu = (record) => (
    <Menu
      onClick={(e) => {
        e.domEvent.stopPropagation();
        showConfirmationModal(
          () => {
            const currentDate = dayjs();
            const archiveDate = currentDate.format("YYYY-MM-DD")
            const archiveTime = currentDate.format("HH:mm:ss")
            onArchive({
              patientId: record?.patientDetails?.patientID,
              from: "Lab",
              archiveDate: archiveDate,
              archiveTime: archiveTime
            })
          },
          "Archive Patient",
          "Are you sure you want to archive this patient?",
          "Cancel",
          "Proceed"
        );
      }}
    >
      <Menu.Item key="delete" icon={<DeleteOutlined />}>
        Archive
      </Menu.Item>
    </Menu>
  );

  const getOrderStatus = useMemo(() => {
    let names = [...new Set(tableData.map((data) => data.orderStatus))];
    let data = [];
    names = names.sort((a, b) => {
      const lowerA = a.toLowerCase();
      const lowerB = b.toLowerCase();

      if (lowerA < lowerB) {
        return -1;
      }
      if (lowerA > lowerB) {
        return 1;
      }
      return 0;
    });

    names.forEach((value) => {
      data.push({
        text: value,
        value: value,
      });
    });
    return data;
  }, [tableData]);

  const getSLAStatus = () => {
    // let data = [
    //   {
    //     text: "1 Day",
    //     value: 1
    //   },
    //   {
    //     text: "2 Days",
    //     value: 2
    //   },
    //   {
    //     text: "3 Days",
    //     value: 3
    //   },
    //   {
    //     text: "4 Days",
    //     value: 4
    //   },
    //   {
    //     text: "5 Days",
    //     value: 5
    //   },
    //   {
    //     text: "6 Days",
    //     value: 6
    //   },
    //   {
    //     text: "7 Days",
    //     value: 7
    //   },
    //   {
    //     text: "8 Days",
    //     value: 8
    //   },
    //   {
    //     text: "9+ Days",
    //     value: 9
    //   },

    // ];
    let data = [
      {
        text: (
          <span className="color-filter-wrapper">
            <span>Early</span>
            <Tag className="color-tag" color={SLA_COLORS.CYAN} />
          </span>
        ),
        value: "early",
      },
      {
        text: (
          <span className="color-filter-wrapper">
            <span>On Time</span>
            <Tag className="color-tag" color={SLA_COLORS.AQUA} />
          </span>
        ),
        value: "ontime",
      },
      {
        text: (
          <span className="color-filter-wrapper">
            <span>Due</span>
            <Tag className="color-tag" color={SLA_COLORS.RED} />
          </span>
        ),
        value: "due",
      },
      {
        text: (
          <span className="color-filter-wrapper">
            <span>Late</span>
            <Tag className="color-tag" color={SLA_COLORS.PINK} />
          </span>
        ),
        value: "late",
      },
    ];
    return data;
  };

  const user = (details) => {
    return (
      <>
        <Row>
          <p style={{ fontWeight: "600", marginBottom: "0", textTransform: "capitalize",wordBreak:"break-word" }}>
            {" "}
            {details?.patientName}
          </p>
        </Row>
        <Row>
          <p style={{ fontWeight: "300", marginBottom: "0" }}> {details?.patientID}</p>
        </Row>
      </>
    );
  };
  const clinicianDetails = (details) => {
    return (
      <>
        <Row>
          <p style={{ fontWeight: "600", marginBottom: "0" }}>{details.clinicName}</p>
        </Row>
        <Row>
          <p style={{ fontWeight: "300", marginBottom: "0" }}> {details.clinicLabel}</p>
        </Row>
      </>
    );
  };

  const assigneeFilterList = useMemo(() => {
    const list = staffMembers?.map((el) => ({ text: el.label, value: el.value }));

    return [{ text: "Unassigned", value: null }].concat(list);
  }, [staffMembers]);
  const columns = [
    {
      width: 170,
      title: columnTitleElement("PATIENT DETAILS"),
      dataIndex: "patientDetails",
      key: "patientDetails",
      render: (details) => user(details),
    },
    // {
    //   width: 140,
    //   title: columnTitleElement("PATIENT NAME"),
    //   dataIndex: "patientName",
    //   key: "patientName",
    //   // filters: getClinicNames(),
    //   // filterMultiple: true,
    //   // onFilter: (value, record) => record.clinicName.indexOf(value) === 0,
    // },
    {
      width: 190,
      title: columnTitleElement("CLINICIAN DETAILS"),
      dataIndex: "clinicianDetails",
      key: "clinicianDetails",
      sortDirections: ["descend", "ascend"],
      render: (details) => clinicianDetails(details),
      sorter: (a, b) => {
        // Convert clinicName to lowercase for case-insensitive comparison
        const nameA = a?.clinicianDetails?.clinicName?.toLowerCase();
        const nameB = b?.clinicianDetails?.clinicName?.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      },
      filtered: tableData.length !== patientsFilterData.length,
      filterDropdown: ({ confirm, clearFilters }) => (
        <ClinicNameFilter
          clearFilters={clearFilters}
          confirm={confirm}
          tableData={tableData}
          setPatientsFilterData={setPatientsFilterData}
          patientsFilterData={patientsFilterData}
          setTotalRecords={setTotalRecords}
        />
      ),
    },
    {
      width: 130,
      title: columnTitleElement("SERVICE TYPE"),
      dataIndex: "serviceType",
      key: "serviceType",
    },
    {
      width: 160,
      title: columnTitleElement("DATE CREATED"),
      dataIndex: "dateCreated",
      key: "dateCreated",
      filtered: (fromDate && endDate),
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDataRangePicker
          clearFilters={clearFilters}
          confirm={confirm}
          handleDateFilter={handleDateFilter}
          fromDate={fromDate}
          endingDate={endDate}
        />
      ),
    },
    {
      width: 160,
      title: columnTitleElement("DATE ORDERED"),
      dataIndex: "dateOrdered",
      key: "dateOrdered",
      filtered: (fromDateOrdered && endDateOrdered),
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDataRangePicker
          clearFilters={clearFilters}
          confirm={confirm}
          handleDateFilter={handleDateOrderedFilter}
        />
      ),
    },
    {
      width: 160,
      title: columnTitleElement("ASSIGNEE"),
      dataIndex: "assignee",
      key: "assignee",
      filterMultiple: true,
      onFilter: (value, record) => {
        // Ensure you're filtering based on the record's `assignee` value.
        return record.staffId === value;
      },
      filteredValue: assigneeFilterValues,
      filters: assigneeFilterList,
      //   filterDropdown: ({ confirm, clearFilters }) => (
      //     <FilterDataRangePicker
      //       clearFilters={clearFilters}
      //       confirm={confirm}
      //       handleDateFilter={handleDateOrderedFilter}
      //     />
      //   ),

      render: (text, record) => {
        return (
          <Assignee
            handlePatientAssignee={handlePatientAssignee}
            staffMembers={staffMembers}
            row={record}
          />
        );
      },
    },
    {
      width: 190,
      title: columnTitleElement("ORDER STATUS"),
      key: "orderStatus",
      dataIndex: "orderStatus",
      render: (orderStatus) => (
        <>
          <AppTag style={{ borderRadius: "10px" }} key={orderStatus} text={orderStatus?.toUpperCase()}/>
      
        </>
      ),
      filters: getOrderStatus,
      filterMultiple: true,
      defaultFilteredValue: getOrderStatusFilter(statusParams),
      onFilter: (value, record) => {
        return record.orderStatus.indexOf(value) === 0;
      },
    },
    {
      width: 130,
      title: columnTitleElement("TASK TIME"),
      key: "sla",
      dataIndex: "sla",
      render: (sla) => {
        if (!sla) {
          return "-";
        }
        return (
          <>
            <Tag
              style={{
                borderRadius: "1.5rem",
                color: sla.colorCode != SLA_COLORS.CYAN ? "#FFF" : "#05A2BD",
                padding: "0.4rem 0.8rem"
              }}
              color={sla.colorCode}
            >
              {sla.totalDays} DAY{sla.totalDays > 1 ? "S" : ""}
            </Tag>
          </>
        );
      },
      filters: getSLAStatus(),
      defaultFilteredValue: getDurationStatusFilter(statusParams),
      // defaultFilteredValue: ['late'],
      filterMultiple: true,
      // onFilter: (value, record) => {
      //   console.log("recordddddddddddddddd", record?.sla?.colorCode)
      //    return record.sla.colorCode === 0;
      // },
    },
    {
      width: 80,
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (value, record) => {
        return (
          <Tooltip title={"Archived"}>
            <Dropdown overlay={renderDropdownMenu(record)} trigger={["click"]}>
              <span onClick={(e) => e.stopPropagation()}>
                <MoreOutlined style={{ cursor: "pointer" }} />
              </span>
            </Dropdown>
          </Tooltip>
        );
      },
    },
  ];
  let locale = {
    emptyText: (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Patients to Show</span>} />
    ),
  };

  const onRowClick = (event, record, recordIndex) => {
    if (record.serviceType === "Treatment") {
      if (record.orderStatus === "TP Setup Requested") {
        const location = {
          pathname: `/lab/patient/${record.patientDetails.patientID}`,
          state: { currentActiveTab: "prescription" },
        };
        history.push(location);
      } else {
        const location = {
          pathname: `/lab/patient/${record.patientDetails.patientID}`,
          state: { currentActiveTab: "treatment" },
        };
        history.push(location);
      }
    } else {
      if (record.orderStatus === "TP Setup Requested") {
        const location = {
          pathname: `/lab/patient/${record.patientDetails.patientID}`,
          state: { currentActiveTab: "prescription" },
        };
        history.push(location);
      } else if (record.orderStatus === "Refinement Requested") {
        const location = {
          pathname: `/lab/patient/${record.patientDetails.patientID}`,
          state: { currentActiveTab: "treatment" },
        };
        history.push(location);
      } else {
        history.push(`/lab/patient/${record.patientDetails.patientID}`);
      }
    }
  };

  const onUserTabChange = (e) => {
    const { value } = e.target;

    let valuesArray = [];

    if (value === USER_TABS.USER && subUser?.id) {
      valuesArray.push(subUser?.id);
    }
    setAssigneeFilterValues(valuesArray);
    setPatientTab(value);
  };

  return (
    <div style={{ width: "100%" }}>
      <Row style={{ justifyContent: "space-between" }}>
        <Col style={{ textAlign: "start" }}>
          <div className="headerListPatient">
            <Title level={3}>Patients List</Title>
            {patientsFilterData?.length > 0 && (
              <ShowTotalNumber total={patientsFilterData?.length} className={"patientListLength"} />
            )}
          </div>
        </Col>
        <Col>
          <span style={{ marginRight: "1rem" }}>
            <Radio.Group
              size="large"
              buttonStyle="solid"
              value={patientTab}
              onChange={onUserTabChange}
            >
              <Radio.Button value={USER_TABS.ALL}>All</Radio.Button>
              <Radio.Button value={USER_TABS.USER}>My Cases</Radio.Button>
            </Radio.Group>
          </span>

          <Input.Search
            onSearch={(e) => onSearch(e.trim())}
            allowClear={true}
            enterButton={true}
            // onChange={this.onSearchValue}
            // enterButton={true}
            // prefix={<SearchOutlined />}
            addonAfter={null}
            className="searchBox"
            placeholder="Search by Patient ID, Name, Clinic name..."
            size="large"
            style={{
              width: 331,
            }}
          />
        </Col>
      </Row>

      <Table
        scroll={{
          y: 800,
        }}
        style={{ width: "100%", overflow: "hidden", marginTop: "0.5rem" }}
        columns={columns}
        dataSource={patientsFilterData}
        pagination={paginationObj}
        onChange={(pagination, filters, sorter) => {
          if (filters.assignee) {
            setAssigneeFilterValues(filters.assignee);
          }
          onPagination(pagination, filters, sorter);
        }}
        onRow={(record, recordIndex) => ({
          onClick: (event) => onRowClick(event, record, recordIndex),
        })}
        locale={locale}
      />
      {patientsFilterData?.length > 0 && <div style={{ position: 'relative', display: 'block', marginTop : '-47px', width: 'fit-content', zIndex: 1 }}>
        <Row style={{ alignItems: 'center', alignSelf: 'flex-start'}}>
          <DeleteOutlined />
          <Link style={{ marginLeft: '5px', color: primaryColor}} to='/lab/archivedPatients'>Archived Patients</Link>
        </Row>
        <div></div>
      </div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps)(PatientListComponent);
