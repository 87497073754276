import { useState, useEffect, useImperativeHandle,forwardRef , useRef} from "react";
import { Form, Spin, message } from "antd";
import TeethChart from "./components/TeethChart/TeethChart";
import Occlusion from "./components/Occlusion/Occlusion";
import Periodontium from "./components/Periodontium/Periodontium";
import axios from "axios";

import IPRAlignment from "./components/IPRAlignment/IPRAlignment";
import Duration from "./components/Duration/Duration";
import Treatment from "./components/Treatment/Treatment";
import Footer from "./components/FormFooter/Footer";
import TreatmentTypes from "./components/TreatmentTypes/TreatmentTypes";
import SmileTreatmentType from "./components/SmileTreatmentType/SmileTreatmentType";
import "./prescriptionModal.scss";
import { getProducts, getResource } from "api/lab";
import { phasingPrescriptionCheck } from "./constants";
import { companyType } from "colors-config";
import SGTreatmentType from "./components/SmileTreatmentType/SGTreatmentType";
import { initialTreatmentTypesFormData } from "utils/ipr";

const PatientPrescriptionForm = (
  {
    prescriptionForm,
    labName,
    labId,
    addPatientRef,
    teethChartRef,
    onPrescriptionSubmit,
    clinicId,
  },
  ref
) => {
  const targetRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [alignerComments, setAlignerComments] = useState([false]);
  const [termsAndConditionsURL, setTermsAndConditionsURL] = useState("");
  const [dataAcquisitionURL, setDataAcquisitionURL] = useState("");
  const [skeletal, setSkeletal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [dental, setDental] = useState();
  const [labProducts, setLabProducts] = useState([]);
  const [showPrescriptionPhasing, setShowPrescriptionPhasing] = useState(true);
  const [treatmentOptions, setTreatmentOptions] = useState([]);


  useImperativeHandle(ref, () => ({
    setAlignerComments,
  }));
  const PUBLIC_API_BASE_URL = process.env.REACT_APP_CLINIC_BASE_URL;

  useEffect(() => {
    async function fetchLabOptions(id) {
      try {
        const durationRes = await axios.get(
          `${PUBLIC_API_BASE_URL}/clinic/prescription/duration/get?clinicId=${id}`
        );
        const productsRes = await axios.get(
          `${PUBLIC_API_BASE_URL}/clinic/prescription/product/get?clinicId=${id}`
        );

        setProductList(productsRes?.data?.body?.data);
        setDurationList(durationRes?.data?.body?.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (clinicId) {
      fetchLabOptions(clinicId);
    }
  }, [clinicId]);

  const getAllLabProducts = async labId => {
    try {
      const labProductsRes = await getProducts(labId);
      
      if (labProductsRes?.data?.labProducts?.length) {
        setLabProducts(labProductsRes.data.labProducts);
      }
      if (labProductsRes?.data?.prescriptionPrducts?.length) {
        setTreatmentOptions(labProductsRes.data.prescriptionPrducts);
      }
      const sgTreatmentTypesData = prescriptionForm.getFieldValue("sgTreatmentTypes")
      if(!sgTreatmentTypesData?.length){
        prescriptionForm.setFieldsValue({
          sgTreatmentTypes: initialTreatmentTypesFormData(labProductsRes.data.prescriptionPrducts),
        });
      }
    } catch (error) {
      message.error("Some Error occured!");
      console.error(error);
    }
  };

  useEffect(() => {
    if (labId) {
      updateTermsApi(labId);
      getAllLabProducts(labId);
    }
  }, [labId]);

  const updateTermsApi = async lab => {
    try {
      const url = await getResource(labId);
      setTermsAndConditionsURL(url?.data?.body?.terms_and_conditions?.url);
      setDataAcquisitionURL(url?.data?.body?.data_acquisition_form?.url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const initial = {
      // treatmentTypes: [
      //   { aligner: "Light", arches: "Upper and Lower", whitening: "no" },
      // ],
      labId: labId,
      duration: "2 weeks",
      // aligner: productName,
    };

    prescriptionForm.setFieldsValue(initial);
  }, [labId, prescriptionForm]);

  const onFinish = async values => {
    //  console.log("teethChartRef", teethChartRef);
    let flattenedData = [];
    // if (teethChartRef.current) {
    //   flattenedData = teethChartRef.current?.flatMap((teeth) => [
    //     { ...teeth, addOns: teeth.addOns || [] },
    //     ...(teeth.addOns || []),
    //   ]);
    // }
    if (teethChartRef.current) {
      flattenedData = teethChartRef.current.flatMap(teeth => {
        const addOnsWithInheritedToothNo = (teeth.addOns || []).map(addOn => ({
          ...addOn,
          toothNo: addOn.toothNo || teeth.toothNo, // Inherit toothNo if null or empty
        }));
        return [
          { ...teeth, addOns: addOnsWithInheritedToothNo },
          ...addOnsWithInheritedToothNo,
        ];
      });
    }
    // console.log("flattenedData", flattenedData)
    if (flattenedData.length > 0) {
      const flatCheck = teethChartRef.current?.filter(
        item => item.toothNo === null || item.toothNo === ""
      );
      // console.log('flatCheck', flatCheck)
      if (flatCheck.length > 0) {
        return message.error("Selected tooth for status is missing");
      }
    }

    teethChartRef.current = flattenedData;

    onPrescriptionSubmit(values);
  };

  const onFormChange = (name, value) => {
    if (name === "phasing") {
      const check = phasingPrescriptionCheck(value);
      setShowPrescriptionPhasing(check);
    }
    if (name === "labProductId") {
      const findLabProduct = labProducts.find(obj => obj.id == value);
      if (findLabProduct) {
        prescriptionForm.setFieldsValue({
          labProductPrice: `${findLabProduct.price} ${findLabProduct.currency}`,
        });
      } else {
        prescriptionForm.setFieldsValue({ labProductPrice: "" });
      }
    }
  };
  const onFinishFailed = errorInfo => {
    // console.log("Failed:", errorInfo);
     targetRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
  };
  const disabled = false;

  return (
    <div className="prescription-form-container">
      <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          form={prescriptionForm}
          name="prescription-form"
          className="prescription-form"
        >
          <div className="formRow">
            <div
              style={{
                borderRadius: "8px",
                padding: "10px 15px 15px 15px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                marginBottom: "10px",
              }}
            >
              {/* {companyType === 5 || companyType === 6 || companyType === 7 ? ( */}
              {companyType === 1 ?
                <SGTreatmentType
                  onFormChange={onFormChange}
                  disabled={disabled}
                  showPrescriptionPhasing={showPrescriptionPhasing}
                  prescriptionForm={prescriptionForm}
                  treatmentOptions={treatmentOptions}
                />
                : <SmileTreatmentType
                  onFormChange={onFormChange}
                  disabled={disabled}
                  showPrescriptionPhasing={showPrescriptionPhasing}
                  treatmentOptions={treatmentOptions}
                />
              }
              {/*) : (
                <TreatmentTypes
                  alignerComments={alignerComments}
                  setAlignerComments={setAlignerComments}
                  productList={productList}
                  onFormChange={onFormChange}
                  disabled={disabled}
                  // prescriptionForm={prescriptionForm}
                  // formDataObjectRef={formDataObjectRef}
                  labProducts={labProducts}
                />
              )}*/}

              <Treatment
                onFormChange={onFormChange}
                disabled={disabled}
                prescriptionForm={prescriptionForm}
              />
              <IPRAlignment onFormChange={onFormChange} disabled={disabled} />
              <Duration
                durationList={durationList}
                labName={labName}
                labId={labId}
                setTermsAndConditionsURL={setTermsAndConditionsURL}
                setDataAcquisitionURL={setDataAcquisitionURL}
                onFormChange={onFormChange}
                disabled={disabled}
              />

              {/* <Footer
                termsAndConditionsURL={termsAndConditionsURL}
                dataAcquisitionURL={dataAcquisitionURL}
                disabled={disabled}
              /> */}
            </div>
            <div
              style={{
                borderRadius: "8px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                marginBottom: "10px",
              }}
            >
              <TeethChart
                teethChartRef={teethChartRef}
                // teethChartDraftRef={teethChartDraftRef}
                // country={clinic?.country}
                country={""}
                disabled={disabled}
                // clinicId={clinic?.clinicId}
                // teethStructureValue={clinic?.teethStructure}
              />
              <Occlusion
                onFormChange={onFormChange}
                disabled={disabled}
                skeletal={skeletal}
                dental={dental}
                setSkeletal={setSkeletal}
                setDental={setDental}
              />
              <Periodontium onFormChange={onFormChange} disabled={disabled} />
            </div>
          </div>
        </Form>
        <div id="targetDiv" ref={targetRef} style={{padding:"10px",height:"20px"}}/>
      </Spin>
    </div>
  );
};

export default forwardRef(PatientPrescriptionForm);
