import { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import TreatmentAligners from "./TreatmentAligners";
import { Dropdown, Row, Select, Typography, Modal, Tooltip } from "antd";
import { DownOutlined } from "@ant-design/icons";
import StatusMessageComponent from "../Services/StatusMessageComponent";
import OlderPlansModal from "../../../../shared/modals/olderPlansModal";
import { CheckCircleOutlined } from "@ant-design/icons";
// import TitleButton from "./TitleButton";
import RequestRefinementModal from "../requestRefinementModal";
import { ReactComponent as ExpandIcon } from "../../../../../assets/images/expand_icon.svg";
import { primaryColor } from "colors-config";
import { iprJawMappers } from "utils/ipr";
import IPRDetail from "components/private/IPRDetail/IPRDetail";
import RefinementAdditionalDetails from "../../RefinementAdditionalDetails/RefinementAdditionalDetails";
import styles from "../treatmentSetup.module.scss";
import TreatmentFrame from "./TreatmentFrame";
import AppButton from "components/shared/AppButton/AppButton";
import { TREATMENT_ORDER_KEY, APPLIANCES_ORDER_KEY } from "constants";
import TreatmentLinkUpdateModal from "./TreatmentLinkUpdateModal";
// import { placeOrderItems } from "utils/order";
// import ShowTreatmentVersion from "./ShowTreatmentVersion";
const { Option } = Select;

const { Title } = Typography;

const TreatmentSetupComponent = ({
  wholeTreatmentPlan,
  treatment,
  checkTreatment,
  handleChange,
  isTreatmentCreationAllowed,
  patientInfo,
  setDeleteModalOpen,
  setShowTreatmentSetupModal,
  setShowOlderPlansModal,
  showOlderPlansModal,
  treatmentPlans,
  deleteModalOpen,
  handleModalCancel,
  deleteModalLoading,
  handleModalOk,
  selectedTreatment,
  setSelectedTreatment,
  // labOrders,
  patientOrders,
  refinementData,
  patientOrderStatus,
  alignerOrders,
  onPlaceOrderClickFromTP,
  labId,
  getRefinementDetails,
  activeTreatment,
  refinements,
  aligners,
  getData,
  setOrderType,
}) => {

  const [refinementModalOpen, setRefinementModalOpen] = useState(false);
  const [
    refinementAdditionalDetailModalVisible,
    setRefinementAdditionalDetailModalVisible,
  ] = useState(false);

  const [upperAligners, setUpperAligners] = useState([]);
  const [lowerAligners, setLowerAligners] = useState([]);

  const fullLowerAlignersRef = useRef([]);
  const fullUpperAlignersRef = useRef([]);
  const [alignersLabel, setAlignersLabel] = useState([]);

  const [lowerAttachments, setLowerAttachments] = useState([]);
  const [upperAttachments, setUpperAttachments] = useState([]);
  const [fullUpperAttachments, setFullUpperAttachments] = useState([]);
  const [fullLowerAttachments, setFullLowerAttachments] = useState([]);
  const [attachmentsLabel, setAttachmentsLabel] = useState([]);
  const [treatmentLabOrders, setTreatmentLabOrders] = useState([]);

  const [treatmentLinkModalOpen, setTreatmentLinkModalOpen] = useState(false);

  useEffect(() => {
    if (Array.isArray(alignerOrders)) {
      const filterTreatmentOrders = alignerOrders.filter(obj => obj.othersOrder === 0 || !obj.othersOrder);
      setTreatmentLabOrders(filterTreatmentOrders);
    }
  }, [alignerOrders])

  const isRefinementOrdered = useMemo(() => {
    let ordered = false;
    const orderTpIds = treatmentLabOrders.map(order => order.lab_treatment_id);
    refinements.forEach(r => {
      if (orderTpIds.includes(r.lab_treatment_id)) {
        ordered = true;
      }
    });

    return ordered;
  }, [refinements, treatmentLabOrders]);

  const checkPatientOrder = useMemo(() => {
    let value = true;
    const checkTreatmentList = wholeTreatmentPlan.filter(tObj =>
      refinementData ? tObj.revision_plan_version : !tObj.revision_plan_version
    );
    if (checkTreatmentList.length && treatmentLabOrders) {
      checkTreatmentList.forEach(treatmentObj => {
        const findTreatment = treatmentLabOrders.find(
          obj => obj.lab_treatment_id == treatmentObj.lab_treatment_id
        );
        if (findTreatment) {
          value = false;
        }
      });
    }

    if (refinementData && !value) {
      return false;
    } else if (refinementData && value) {
      return true;
    } else {
      return value;
    }
  }, [wholeTreatmentPlan, treatmentLabOrders]);

  const handleIPr = selectedTreatmentData => {
    let iprExtractedData = {
      upperJaw: [],
      lowerJaw: [],
      alignerLabel: [],
    };
    if (selectedTreatmentData?.iprData?.length) {
      const data = iprJawMappers(
        selectedTreatmentData.iprData,
        selectedTreatmentData
      );
      iprExtractedData = {
        ...data,
      };
    }
    setAlignersLabel(iprExtractedData.alignerLabel);
    setLowerAligners(iprExtractedData.lowerJaw);
    setUpperAligners(iprExtractedData.upperJaw);
    fullUpperAlignersRef.current = iprExtractedData.upperJaw;
    fullLowerAlignersRef.current = iprExtractedData.lowerJaw;
  };

  const handleAttachments = selectedTreatmentData => {
    let iprExtractedData = {
      upperJaw: [],
      lowerJaw: [],
      alignerLabel: [],
    };

    if (selectedTreatmentData?.attachmentData?.length) {
      const data = iprJawMappers(
        selectedTreatmentData.attachmentData,
        selectedTreatmentData
      );
      iprExtractedData = {
        ...data,
      };
    }
    setUpperAttachments(iprExtractedData.upperJaw);
    setFullUpperAttachments(iprExtractedData.upperJaw);
    setLowerAttachments(iprExtractedData.lowerJaw);
    setFullLowerAttachments(iprExtractedData.lowerJaw);
    setAttachmentsLabel(iprExtractedData.alignerLabel);
  };

  useEffect(() => {
    if (treatment) {
      handleIPr(treatment);
      handleAttachments(treatment);
    }
  }, [treatment]);

  const onAttachmentsLabelClicked = index => {
    let uniqueSessions = [...attachmentsLabel];
    uniqueSessions[index].checked = !uniqueSessions[index].checked;
    setAttachmentsLabel(uniqueSessions);

    let fullUpperAlignersTemp = [];
    let fullLowerAlignersTemp = [];
    fullUpperAttachments.forEach(obj => {
      const findAligner = uniqueSessions.find(
        uObj => uObj.aligner_count == obj.aligner_count
      );
      if (findAligner && findAligner.checked) {
        fullUpperAlignersTemp.push(obj);
      }
    });

    fullLowerAttachments.forEach(obj => {
      const findAligner = uniqueSessions.find(
        uObj => uObj.aligner_count == obj.aligner_count
      );
      if (findAligner && findAligner.checked) {
        fullLowerAlignersTemp.push(obj);
      }
    });
    setUpperAttachments(fullUpperAlignersTemp);
    setLowerAttachments(fullLowerAlignersTemp);
  };

  const onTreatmentChange = value => {
    const selectedTreatmentData = checkTreatment.find(el => el.value === value);

    if (selectedTreatmentData) {
      // handleIPr(selectedTreatmentData);
      // handleAttachments(selectedTreatmentData);
      handleChange(selectedTreatmentData);
    }

    setSelectedTreatment(selectedTreatmentData);
  };

  const detailHeading = tpData => {
    if (tpData.revision_plan_version) {
      return `Refinement ${tpData.revision_plan_version}.0 Details`;
    }

    return `Treatment ${tpData.treatment_plan_version}.0 Details`;
  };

  const onAddRefinementPlan = () => {
    setRefinementAdditionalDetailModalVisible(true);
  };

  const checkTreatmentOrderBtnShow = (orders, treatments, selectedTp) => {
    if (!treatment) {
      return false;
    }
    if (!orders?.length) {
      return true;
    }
    const isTP = !selectedTp?.revision_plan_version ? true : false;
    if (!isTP) {
      return false;
    }

    let haveOrder = false;
    orders.forEach(order => {
      treatments.forEach(tp => {
        if (order.lab_treatment_id === tp.lab_treatment_id) {
          haveOrder = true;
        }
      });
    });

    if (haveOrder) {
      return false;
    }

    return true;
  };

  const checkRefinementOrderBtnShow = (orders, treatments, selectedTp) => {
    if (!treatment) {
      return false;
    }
    if (!orders?.length) {
      return false;
    }
    const isRP = selectedTp?.revision_plan_version ? true : false;
    if (!isRP) {
      return false;
    }

    if (selectedTp?.refinement_applied) {
      return false;
    }

    let haveOrder = false;
    orders.forEach(order => {
      treatments.forEach(tp => {
        if (tp?.revision_plan_version && !tp?.refinement_applied) {
          if (order.lab_treatment_id === tp.lab_treatment_id) {
            haveOrder = true;
          }
        }
      });
    });

    if (haveOrder) {
      return false;
    }

    return true;
  };

  const handleMenuClick = key => {
    setOrderType(key);
    onPlaceOrderClickFromTP(selectedTreatment?.value);
  };

  const placeOrderItems = (handleOnClick, showTpOrder = false) => {

    const options = [
      {
        key: APPLIANCES_ORDER_KEY,
        label: "Appliances",
        onClick: () => handleOnClick(APPLIANCES_ORDER_KEY),
      },
    ];

    if (showTpOrder) {
      options.unshift({
        key: TREATMENT_ORDER_KEY,
        label: "Treatment",
        onClick: () => handleOnClick(TREATMENT_ORDER_KEY),
      });
    }

    return options;
  };

  const onTreatmentModalOpen = () => {
    setTreatmentLinkModalOpen(true)
  }
  
console.log("treatment",treatment);


  return (
    <div>
      <Row justify="space-between" className="patient-treatment-details">
        <div className={styles["treatment-rows-container"]}>
          <div className={styles["treatment-row"]}>
            <Title level={5} className={styles["treatment-row"]}>
              {checkTreatment.length > 1 ? (
                <div>
                  <Select
                    value={selectedTreatment?.value || 0}
                    onChange={onTreatmentChange}
                    // options={checkTreatment}
                  >
                    {checkTreatment.map((item, index) => {
                      return (
                        <Option
                          className="treatment-selectbox-multi"
                          key={index}
                          value={item.value}
                        >
                          {item.label}{" "}
                          {item.approved ? <CheckCircleOutlined /> : ""}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              ) : (
                `Treatment Plan Version ${treatment?.treatment_plan_version}.0`
              )}

              <Tooltip title="Open in another tab">
                <ExpandIcon
                  className="image-container"
                  style={{
                    width: "20px",
                    height: "auto",
                    marginLeft: "5px",
                    cursor: "pointer",
                    marginBottom: "-3px",
                    fill: primaryColor,
                  }}
                  onClick={() => {
                    window.open(treatment?.treatment_plan_link);
                  }}
                />
              </Tooltip>
            </Title>

            <StatusMessageComponent
              patientInfo={patientInfo}
              treatment={treatment}
              setShowTreatmentSetupModal={setShowTreatmentSetupModal}
              setDeleteModalOpen={setDeleteModalOpen}
              isTreatmentCreationAllowed={isTreatmentCreationAllowed}
              patientOrders={treatmentLabOrders}
              refinementData={refinementData}
              setRefinementModalOpen={setRefinementModalOpen}
              patientOrderStatus={patientOrderStatus}
            />
          </div>
          <div className={styles["treatment-row"]}>
            <div style={{gap:"5px", flexWrap:'wrap'}} className="ipr-list-date">
              {/* <span>
                {treatment?.approval_status !== "pending" &&
                  getFormatDate(treatment?.date_created)}
              </span> */}

              {(patientInfo.clinic_patient_journey_status_id === 14 ||
                patientInfo.clinic_patient_journey_status_id === 15) &&
                treatmentLabOrders?.length ? (
                <AppButton
                  type="primary"
                  shape="round"
                  onClick={onAddRefinementPlan}
                >
                  Add Refinement Plan
                </AppButton>
              ) : null}
              {refinementData &&
                patientInfo.clinic_patient_journey_status_id !== 14 &&
                patientInfo.clinic_patient_journey_status_id !== 15 && (
                  <AppButton
                    shape="round"
                    type="default"
                    onClick={() => setRefinementModalOpen(true)}
                  >
                    View Refinement Request
                  </AppButton>
                )}

              {!refinementData &&
                !treatmentLabOrders.length &&
                wholeTreatmentPlan.length && (
                  <div
                    style={{
                      marginLeft: "10px",
                      paddingRight: "5px",
                    }}
                  >
                    <AppButton
                      onClick={() => setShowTreatmentSetupModal(true)}
                      type="primary"
                      disabled={!isTreatmentCreationAllowed}
                      shape="round"
                    >
                      Update Treatment
                    </AppButton>
                  </div>
                )}
              {refinementData && !isRefinementOrdered && (
                <div
                  style={{
                    marginLeft: "10px",
                    paddingRight: "5px",
                  }}
                >
                  <AppButton
                    onClick={() => setShowTreatmentSetupModal(true)}
                    type="primary"
                    disabled={!isTreatmentCreationAllowed}
                    shape="round"
                  >
                    Update Refinement
                  </AppButton>
                </div>
              )}

              {checkRefinementOrderBtnShow(
                treatmentLabOrders,
                wholeTreatmentPlan,
                treatment
              ) && (
                <AppButton
                  type="primary"
                  shape="round"
                  onClick={() =>
                    onPlaceOrderClickFromTP(selectedTreatment?.value)
                  }
                >
                  Order Refinement Plan
                </AppButton>
              )}

              <Dropdown
                menu={{
                  items: placeOrderItems(
                    handleMenuClick,
                    checkTreatmentOrderBtnShow(
                      treatmentLabOrders,
                      wholeTreatmentPlan,
                      treatment
                    )
                  ),
                }}
              >
                <AppButton shape="round" type="primary">
                  Place Order <DownOutlined />
                </AppButton>
              </Dropdown>
            </div>
          </div>
        </div>
      </Row>
      <TreatmentFrame
        treatment={treatment}
        onTreatmentModalOpen={onTreatmentModalOpen}
      />
      <Row justify="space-between" className="detail-container">
        <p className="text-container">{detailHeading(treatment)}</p>
      </Row>
      <TreatmentAligners treatment={treatment} />
      <IPRDetail
        upperAligners={upperAligners}
        lowerAligners={lowerAligners}
        fullLowerAligners={fullLowerAlignersRef.current}
        alignersLabel={alignersLabel}
        setAlignersLabel={setAlignersLabel}
        fullUpperAligners={fullUpperAlignersRef.current}
        setUpperAligners={setUpperAligners}
        setLowerAligners={setLowerAligners}
        treatment={treatment}
        attachmentsLabel={attachmentsLabel}
        onAttachmentsLabelClicked={onAttachmentsLabelClicked}
        lowerAttachments={lowerAttachments}
        upperAttachments={upperAttachments}
        patientInfo={patientInfo}
      />

      {deleteModalOpen && (
        <Modal
          open={deleteModalOpen}
          title={"Cancel Treatment"}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          confirmLoading={deleteModalLoading}
        >
          <p>Are you sure you want to cancel treatment?</p>
        </Modal>
      )}

      {refinementModalOpen && (
        <RequestRefinementModal
          refinementModalOpen={refinementModalOpen}
          setRefinementModalOpen={setRefinementModalOpen}
          treatment={treatment}
          refinementData={refinementData}
          setShowTreatmentSetupModal={setShowTreatmentSetupModal}
          footerCheck={
            checkPatientOrder ||
            patientInfo.clinic_patient_journey_status_id === 20
          }
        />
      )}
      {treatmentLinkModalOpen && (
        <TreatmentLinkUpdateModal 
        treatmentLinkModalOpen={treatmentLinkModalOpen}
        setTreatmentLinkModalOpen={setTreatmentLinkModalOpen}
        treatment={treatment}
        treatmentPlans={treatmentPlans}
        />
      )}

      {showOlderPlansModal && (
        <OlderPlansModal
          title="View Older Plans"
          treatmentPlans={treatmentPlans}
          setShow={setShowOlderPlansModal}
          showModal={showOlderPlansModal}
          wholeTreatmentPlan={wholeTreatmentPlan}
        />
      )}

      {refinementAdditionalDetailModalVisible && (
        <RefinementAdditionalDetails
          patientOrders={treatmentLabOrders}
          aligners={aligners}
          setShowTreatmentSetupModal={setShowTreatmentSetupModal}
          labId={labId}
          clinicId={patientInfo.clinic_id}
          patientId={patientInfo.patient_id}
          treatmentPlanId={activeTreatment?.lab_treatment_id}
          refinementAdditionalDetailModalVisible={
            refinementAdditionalDetailModalVisible
          }
          setRefinementAdditionalDetailModalVisible={
            setRefinementAdditionalDetailModalVisible
          }
          getRefinementDetails={getRefinementDetails}
          treatmentPlans={treatmentPlans}
          alignerOrders={treatmentLabOrders}
          refinements={refinements}
          getData={getData}
          patientInfo={patientInfo}
        />
      )}
    </div>
  );
};

export default TreatmentSetupComponent;

export const treatmentPlanPropType = PropTypes.shape({
  lab_treatment_id: PropTypes.string.isRequired,
  num_of_upper_aligners: PropTypes.number.isRequired,
  num_of_lower_aligners: PropTypes.number.isRequired,
  attachments: PropTypes.bool.isRequired,
  treatment_plan_link: PropTypes.string.isRequired,
  treatment_plan_version: PropTypes.number.isRequired,
  clinic_id: PropTypes.string.isRequired,
  patient_id: PropTypes.string.isRequired,
  lab_id: PropTypes.string.isRequired,
  date_created: PropTypes.string.isRequired,
  approval_status: PropTypes.string.isRequired,
  case_id: PropTypes.number.isRequired,
  ipr_guide_link: PropTypes.string,
  modify_date: PropTypes.string,
  approved: PropTypes.bool.isRequired,
  revision_plan_version: PropTypes.number,
  additionalDetails: PropTypes.string,
  iprFileId: PropTypes.any,
  labProductId: PropTypes.any,
  quantity: PropTypes.number,
  modifyComment: PropTypes.any,
  phasing: PropTypes.string,
  refinement_applied: PropTypes.bool.isRequired,
  price: PropTypes.string,
  free: PropTypes.any,
  sendPatientDate: PropTypes.any,
  patientApproval: PropTypes.bool.isRequired,
  sendToPatient: PropTypes.bool.isRequired,
  treatment_details: PropTypes.string,
  orderedDate: PropTypes.string.isRequired,
  hidePatientPrice: PropTypes.any,
  planType: PropTypes.string.isRequired,
  mediaId: PropTypes.any,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  treatment_media: PropTypes.any,
  ipr_details: PropTypes.arrayOf(PropTypes.any),
  iprData: PropTypes.arrayOf(PropTypes.any),
  attachmentData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      aligner_count: PropTypes.number.isRequired,
      jawType: PropTypes.string.isRequired,
      alignerValues: PropTypes.arrayOf(
        PropTypes.shape({
          teeth: PropTypes.number,
          value: PropTypes.bool.isRequired,
          detail: PropTypes.string,
          attachmentType: PropTypes.string,
        })
      ).isRequired,
      start_date: PropTypes.string,
      completed: PropTypes.number.isRequired,
      labTreatmentId: PropTypes.string.isRequired,
      clinicId: PropTypes.string.isRequired,
      patientId: PropTypes.string.isRequired,
      labId: PropTypes.string.isRequired,
      caseId: PropTypes.number.isRequired,
      refinement: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      checked: PropTypes.bool,
      session: PropTypes.number,
      sessionListName: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});
TreatmentSetupComponent.propTypes = {
  selectedTreatment: treatmentPlanPropType,
  wholeTreatmentPlan: PropTypes.arrayOf(treatmentPlanPropType),
};
