import { useRef, useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import "react-quill/dist/quill.snow.css";
import "quill-mention"; // Import the mention extension
// import 'quill-mention/dist/quill.mention.css';
import styles from './textEditor.module.scss'
// import "./textEditor.scss";

const TextEditor = ({
  editorInput,
  setEditorInput,
  placeholder,
  hideToolbar,
  imageCallback,
  mentionsEnabled,
  atValues,
}) => {
  const quillRef = useRef(null);

  useEffect(() => {
    const editor = quillRef?.current?.getEditor();
    const editorContainer = editor?.root;

    const handlePaste = (event) => {
      const clipboardData = event.clipboardData || window.clipboardData;

      if (clipboardData && clipboardData.items) {
        for (let i = 0; i < clipboardData.items.length; i++) {
          const item = clipboardData.items[i];
          if (item.type.indexOf("image") !== -1) {
            event.preventDefault();
            const file = item.getAsFile();
            imageCallback(file);
          }
        }
        event.preventDefault(); 
        const text = clipboardData.getData("text/plain");
        const strippedText = text.replace(/<\/?[^>]+(>|$)/g, ""); 

        const editor = quillRef.current.getEditor()
        const range = editor.getSelection(true)
        if (range) {
          editor.insertText(range.index, strippedText)
        } else {
          editor.insertText(editor.getLength(), strippedText)
        }
      }


    };

    editorContainer?.addEventListener("paste", handlePaste);
    return () => {
      editorContainer?.removeEventListener("paste", handlePaste);
    };
  }, [imageCallback]);

  const mentionOptions = useMemo(() => {
    if (!mentionsEnabled) {
      return null;
    }

    return {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      source: function (searchTerm, renderList) {
        if (searchTerm.length === 0) {
          renderList(atValues, searchTerm);
        } else {
          const matches = atValues.filter((value) =>
            value.value.toLowerCase().includes(searchTerm.toLowerCase())
          );
          renderList(matches, searchTerm);
        }
      },
    };
    // eslint-disable-next-line
  }, [mentionsEnabled]);

  const toolbar = useMemo(() => {
    if (hideToolbar) {
      return false;
    }

    return [
      [{ header: [1, 2, false] }],
      [{ list: "bullet" }, { list: "ordered" }, "bold", "italic", "underline"],
    ];
  }, [hideToolbar]);

  return (
    <div className={styles['editor-container']} style={{ width: "100%", height: "100%" }}>
      <ReactQuill
        ref={quillRef}
        placeholder={placeholder || ""}
        value={editorInput}
        preserveWhitespace
        onChange={(value) => {
          setEditorInput(value);
        }}
        modules={{
          toolbar: toolbar,
          mention: mentionOptions,
        }}
      />
    </div>
  );
};

TextEditor.defaultProps = {
  hideToolbar: false,
  placeholder: "",
  imageCallback: () => {},
  mentionsEnabled: false, // Default false
  atValues: [],
};

TextEditor.propTypes = {
  editorInput: PropTypes.string.isRequired,
  setEditorInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  hideToolbar: PropTypes.bool,
  imageCallback: PropTypes.func,
  mentionsEnabled: PropTypes.bool, // enable or disable mentions
  atValues: PropTypes.array,
};

export default TextEditor;
