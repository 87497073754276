import axios from "axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import store from "services/redux/store";
dayjs.extend(timezone);

const baseUrl = process.env.REACT_APP_CLINIC_BASE_URL;
const tenantId = process.env.REACT_APP_SMILE_TENENT_ID;

// const baseUrl = "http://localhost/api/v1";

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 3600000,
});

const userStaffId = localStorage.getItem("userStaffId") || 0;

const getUserStaffId = (staffId) => {
  if (!staffId) {
    const state = store.getState();
    const userStaffId = state?.lab?.subUser?.id || 0;

    return userStaffId;
  }
  return staffId;
};

instance.interceptors.request.use(
  (config) => {
    config.headers["tenantId"] = tenantId;
    config.headers["timezone"] = dayjs.tz.guess();
    config.headers["userStaffId"] = getUserStaffId(userStaffId);
    config.headers["from"] = "lab";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
