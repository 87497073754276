import {
  CLEAR_LAB,
  LAB_LOADED,
  GET_LOV,
  NOTIFICATION_STATE_CHANGE,
  UPDATE_COMMENT_BOX_STATE,
  GET_COUNTRIES_CODES,
  UPDATE_LAB_DATA,
  LAB_NOTIFICATION_LOADED,
  LAB_NOTIFICATION_READ,
  PATIENT_COUNTRY_CODES,
  LOAD_PATIENT_LIST
} from "../actions/types";

const initialState = {
  labId: null,
  labEmail: "",
  labPhoneNumber: "",
  labCountryCode: "",
  labServiceId: "",
  labServiceName: "",
  labName: "",
  role: "super-admin",
  subUser: null,
  clinicNames: [],
  orderStatus: [],
  isNotificationsActive: false,
  openCommentBox: false,
  notificationCount: 0,
  reloadPatientList:false
};

const labReducer = (state = initialState, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case LAB_LOADED:
      return {
        ...state,
        labId: action.payload.lab_id,
        labEmail: action.payload.lab_email,
        labPhoneNumber: action.payload.lab_phone_number,
        labCountryCode: action.payload.lab_country_code_name,
        labServiceId: action.payload.lab_service_id,
        labServiceName: action.payload.lab_service,
        labName: action.payload.lab_name,
        isFirstLogin: action.payload.lab_first_login === 0 ? false : true,
        role: action.payload.role,
        subUser: action.payload.subUser || null,
        openCommentBox: false,
      };
    case GET_LOV:
      return {
        ...state,
        clinicNames: action.payload.clinicNames,
        orderStatus: action.payload.orderStatus,
      };
    case NOTIFICATION_STATE_CHANGE:
      return {
        ...state,
        isNotificationsActive: action.payload.isActive,
      };
    case CLEAR_LAB:
      return {
        ...state,
        labId: null,
        labEmail: null,
        labPhoneNumber: null,
        labCountryCode: null,
        labServiceId: null,
        labServiceName: null,
        labName: null,
        isFirstLogin: false,
      };
    case UPDATE_COMMENT_BOX_STATE:
      return {
        ...state,
        openCommentBox: action.payload,
      };

    case GET_COUNTRIES_CODES:
      const { codes, countries } = action.payload;
      return {
        ...state,
        codes,
        countries,
      };

    case UPDATE_LAB_DATA:
      return {
        ...state,
        ...action.payload.labData,
      };
    case LAB_NOTIFICATION_LOADED:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case LAB_NOTIFICATION_READ:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case PATIENT_COUNTRY_CODES:
      return {
        ...state,
        patientCountryCodesList: action.payload,
      };
    case LOAD_PATIENT_LIST:
      return {
        ...state,
        reloadPatientList: action.payload,
      };
    default:
      return state;
  }
};

export default labReducer;
