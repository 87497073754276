import { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Input, message } from "antd";
import TreatmentUpload from "components/shared/TreatmentUpload/TreatmentUpload";
import { CloseOutlined } from "@ant-design/icons";
import { updateTreatmentLinkAPI } from "api/treatment";
import { uploadAppMediaApi } from "api/lab";


const TreatmentLinkUpdateModal = ({
    treatmentLinkModalOpen,
    setTreatmentLinkModalOpen,
    treatment,
    treatmentPlans
}) => {

    const [form] = Form.useForm();
    const [treatmentFile, setTreatmentFile] = useState(null);
    const [treatmentLinkModalLoading, setTreatmentLinkModalLoading] = useState(false);

    useEffect(() => {
        console.log("treatment", treatment);
        if (treatment?.planType === "FILE") {
            setTreatmentFile(treatment?.treatment_media);
        }
        else {
            setTreatmentFile(null);
            form.setFieldsValue({
                planUrl: treatment.treatment_plan_link
            });
        }
    }, [treatment])
    const handleCancel = () => {
        setTreatmentLinkModalOpen(false);
    };

    const beforeUploadTreatment = (file) => {
        // console.log("beforeUploadTreatment", file)

        setTreatmentFile(file);
        return false;
    };

    const onChange = (info) => {

    };

    const uploadTreatmentFile = async (file, labTreatmentId, patientId, clinicId) => {
        try {
            let formData = new FormData();

            formData.append("file", file);
            formData.append("labTreatmentId", labTreatmentId);
            formData.append("patientId", patientId);
            formData.append("clinicId", clinicId);
            formData.append("uploadedBy", "lab");
            formData.append("model", "treatment");
            const { data } = await uploadAppMediaApi(formData);
            if (data) {
                return data.data;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onFinish = async (values) => {
        try {
            setTreatmentLinkModalLoading(true)
            const { clinic_id, lab_id, patient_id, lab_treatment_id } = treatment;

            if (!treatmentFile) {
                let updateValues = {
                    clinicId: clinic_id,
                    labId: lab_id,
                    patientId: patient_id,
                    treatmentPlanId: lab_treatment_id,
                    planType: "LINK",
                    treatmentPlanLink: values.planUrl
                }
                console.log("updateValues", updateValues);

                await updateTreatmentLinkAPI(updateValues)
            }
            else {
                await uploadTreatmentFile(treatmentFile, lab_treatment_id, patient_id, clinic_id)
            }
            setTreatmentLinkModalLoading(false);
            message.success("Treatment plan updated");
            handleCancel();
            await treatmentPlans();
            

        } catch (error) {
            console.log("error", error);
            setTreatmentLinkModalLoading(false);
            message.error("Some error occured")
        }
    }

    return (
        <>
            <Modal
                open={treatmentLinkModalOpen}
                width={750}
                title="Update Treatment plan 3D model link"
                onOk={() => form.submit()}
                onCancel={handleCancel}
                confirmLoading={treatmentLinkModalLoading}
                okText="Update"
                okButtonProps={{
                    className: "app-button-class",
                }}
                cancelButtonProps={{
                    className: "app-button-class",
                }}
            >
                <Form
                    form={form}
                    className="treatment-setup-form treatment-setup-update-link-form"
                    name="treatment-setup"
                    layout={"vertical"}
                    onFinish={onFinish}
                >
                    <Row align="middle" justify="space-between" style={{ width: "98%" }}>
                        <Col md={18}>
                            {treatmentFile ? (
                                <div>
                                    <label className="required">Treatment plan 3D model link</label>
                                    <div className="treatment-file">
                                        <span>
                                            {treatmentFile?.name}
                                            <CloseOutlined
                                                style={{ marginLeft: "20px" }}
                                                onClick={() => setTreatmentFile(null)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <Form.Item
                                    name="planUrl"
                                    label="Treatment plan 3D model link"
                                    style={{
                                        textAlign: "left",
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "A URL is required",
                                            whitespace: true,
                                        },
                                        {
                                            type: "url",
                                            message:
                                                "This field must be a valid url (start with https://) and end with a domain.",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Model Link"></Input>
                                </Form.Item>
                            )}
                        </Col>
                        <Col md={6}>
                            <div style={{ marginTop: "12px" }}>
                                <TreatmentUpload
                                    maxCount={1}
                                    multiple={false}
                                    beforeUpload={beforeUploadTreatment}
                                    onChange={onChange}
                                    treatmentFile={treatmentFile}
                                    setTreatmentFile={setTreatmentFile}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default TreatmentLinkUpdateModal;
