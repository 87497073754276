import { companyType } from "colors-config";
import { Row, Col, Divider } from "antd";
import { checkTextEditorContent, showPhasingDetails } from "utils/Lab";


const TreatmentInfo = ({ treatment }) => {
  const showAdditionalDetails = (additionalDetails = "") => {
    const hasContent = checkTextEditorContent(additionalDetails);
    return (
      <div
        style={{ fontWeight: "400", textAlign: "justify" }}
      >
        {!hasContent ? (
          "--"
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: treatment?.additionalDetails,
            }}
          />
        )}
      </div>
    );
  };
  return (
    <div>
      <div style={{ padding: "0.5rem" }} className="aligner-component">
        <Row gutter={[12,12]}>
          <Col span={6}>
            <div className="border-right">
              <Row>
                <p className="patient-response">
                  No. of Upper {companyType == 4 ? "Stages" : "Aligners"}
                </p>
              </Row>
              <Row>
                <p className="patient-question">
                  {treatment?.num_of_upper_aligners}
                </p>
              </Row>
            </div>
          </Col>
          <Col span={6}>
            <div className="border-right">
              <Row>
                <p className="patient-response">
                  No. of Lower {companyType == 4 ? "Stages" : "Aligners"}
                </p>
              </Row>
              <Row>
                <p className="patient-question">
                  {treatment?.num_of_lower_aligners}
                </p>
              </Row>
            </div>
          </Col>
        
          {/* <Col span={ companyType === 5 ? 6 : 12}>
          <div className={companyType === 5 ? "border-right" :""}>
              <Row>
                <p className="patient-response">Attachments</p>
              </Row>
              <Row>
                <p className="patient-question">
                  {Boolean(treatment?.attachments) === true ? "Yes" : "No"}
                </p>
              </Row>
            </div>
          </Col> */}

          {
            companyType === 5 ?   <Col span={6}>
            <div>
              <Row>
                <p className="patient-response">Phasing</p>
              </Row>
              <Row>
                <p className="patient-question">
                  {showPhasingDetails(treatment?.phasing, false)}
                </p>
              </Row>
            </div>
          </Col>:""}
          
        </Row>
      </div>
      <div style={{ padding: "0.5rem" , marginTop:"10px"}} className="aligner-component">
        <Row>
          <p className="patient-response">Additional Details</p>
        </Row>
        <Row>{showAdditionalDetails(treatment?.additionalDetails)}</Row>
      </div>
      <Divider/>
    </div>
  );
};

export default TreatmentInfo;
