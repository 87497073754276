import { useState, useRef, useEffect } from "react";
import {
  findMaxPrescriptionVersion,
  prescriptionDataMapper,
} from "components/private/patient-detail/newPrescriptionForm/utils";

import NewPrescriptionForm from "components/private/patient-detail/newPrescriptionForm/PrescriptionForm";
import PrescriptionModal from "components/private/AddPatientModal/AddPatientModal";
import {
  getPatientPrescriptionDetails,
  getPrescriptionByVersion,
} from "components/private/patient-detail/PatientTreatment/api";

import Header from "./Header/Header";
import PrescriptionPDF from "components/private/PrescriptionPDF/PrescriptionPDF";
import ViewScanComponent from "../viewScanComponent";
import { getTreatmentTypes } from "api/clinic";
import { useSelector } from "react-redux";

const companyType = Number(process.env.REACT_APP_COMPANY_TYPE);

const Prescription = ({
  formData,
  patientInfoState,
  patientId,
  setIsPageLoading,
  setTreatmentPrescription,
  prescriptionForm,
  scanUrls,
  setScanUrls,
  clinicInfo,
  getImagesAndQuestionnaire,
  clinicalFiles,
}) => {
  const teethChartRef = useRef(null);
  const prescriptionPdfRef = useRef(null);
  const printFormRef = useRef(null);
  const labDetails = useSelector(state => state.lab);

  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isVersionSelected, setIsVersionSelected] = useState(false);
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [chartValue, setChartValue] = useState("fdi");
  const prescriptionFormRef = useRef(null);

  const [treatmentOptions, setTreatmentOptions] = useState([]);

  const getPrescriptionTreatmentTypes = async () => {
    try {
      const treatmentRes = await getTreatmentTypes(labDetails.labId);
      if (treatmentRes?.data?.data) {
        setTreatmentOptions(treatmentRes?.data?.data);
      }
    } catch (error) {
      //   message.error("Some Error occured!");
      console.error(error);
    }
  };

  useEffect(() => {
    if (labDetails.labId) {
      getPrescriptionTreatmentTypes();
    }
  }, [labDetails]);

  useEffect(() => {
    //  console.log("formData", formData)
    //  console.log("prescriptionForm", prescriptionForm)
    prescriptionFormRef.current = formData;
  }, [formData]);

  const onVersionChange = async value => {
    if (!value) {
      return;
    }
    setIsVersionSelected(true);

    try {
      setIsPageLoading(true);
      const maxVersion = findMaxPrescriptionVersion(
        formData.prescriptionVersions
      );

      let prescription = null;

      if (maxVersion === value) {
        const response = await getPatientPrescriptionDetails(patientId);
        prescription = response?.data?.prescription;
      } else {
        const response = await getPrescriptionByVersion(formData.id, value);
        prescription = {
          ...response.data?.prescription?.prescription_data,
          ...response.data?.prescription?.scan_files_data,
        };
      }

      if (!prescription) {
        return;
      }
      // console.log('Version Change', prescription?.patient_onboarding_images , value)
      const finalObj = prescriptionDataMapper(prescription);
      setTreatmentPrescription(finalObj);
      setScanUrls(prescription?.patient_onboarding_images);
      setSelectedVersion(value);
      teethChartRef.current = prescription.teethChart;
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    //  console.log("formDataaaaa", formData)
    if (formData?.prescriptionVersions?.length > 0 && !isVersionSelected) {
      setSelectedVersion(
        findMaxPrescriptionVersion(formData?.prescriptionVersions)
      );
    }
  }, [formData]);

  return (
    <div
      style={{
        paddingBottom: "20px",
        paddingLeft: "20px",
      }}
    >
      {openPrescriptionModal && (
        <PrescriptionModal
          onVersionChange={onVersionChange}
          showAddPatientsModal={openPrescriptionModal}
          patientData={patientInfoState}
          editPrescription={true}
          setShowAddPatientsModal={setOpenPrescriptionModal}
          scanUrls={scanUrls}
          formData={formData}
          setSelectedVersion={setSelectedVersion}
          prescriptionFormRef={prescriptionFormRef?.current}
          setTreatmentPrescription={setTreatmentPrescription}
          setScanUrls={setScanUrls}
        />
      )}
          <ViewScanComponent
        scanUrls={scanUrls}
        getImagesAndQuestionnaire={getImagesAndQuestionnaire}
        clinicalFiles={clinicalFiles}
        patientId={patientId}
        setScanUrls={setScanUrls}
        clinicId={clinicInfo?.clinic_id}
      />
      <Header
        companyType={companyType}
        selectedVersion={selectedVersion}
        onVersionChange={onVersionChange}
        formData={prescriptionFormRef?.current}
        patientInfoState={patientInfoState}
        printFormRef={printFormRef}
        setOpenPrescriptionModal={setOpenPrescriptionModal}
        prescriptionPdfRef={prescriptionPdfRef}
      />
  
      <div style={{ marginTop: "1rem" }}>
        <NewPrescriptionForm
          disabled={true}
          formData={prescriptionFormRef?.current}
          prescriptionForm={prescriptionForm}
          teethChartRef={teethChartRef}
          printFormRef={printFormRef}
          chartValue={chartValue}
          setChartValue={setChartValue}
        />
      </div>
      <div ref={prescriptionPdfRef}>
        <div style={{ display: "none" }} className="print-part-pdf">
          <PrescriptionPDF
            clinicInfo={clinicInfo}
            prescriptionForm={prescriptionFormRef?.current}
            teethChartData={teethChartRef?.current}
            chartValue={chartValue}
            patientInfo={patientInfoState}
            selectedVersion={selectedVersion}
            patientId={patientId}
            // treatmentId={treatmentId}
            treatmentOptions={treatmentOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default Prescription;
