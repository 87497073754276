import { useState, useEffect, useRef } from "react";
import {
  Radio,
  Row,
  Col,
  Spin,
  message,
  Card,
  Collapse,
  Button,
  Tooltip,
  Form,
  Dropdown,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  getPatientPrescriptionDetails,
  getPrescriptionByVersion,
} from "components/private/patient-detail/PatientTreatment/api";
import { prescriptionDataMapper } from "./newPrescriptionForm/utils/index";
import moment from "moment";
import axios from "axios";
import { findMaxPrescriptionVersion } from "./newPrescriptionForm/utils";
import PatientOrderStepsComponent from "./patientOrderStepsComponent";
import PatientAlignerOrderStepsComponent from "./patientAlignerOrderStepsComponent";
// import ViewScanComponent from "./viewScanComponent";
import ProgressImageComponent from "./progressImagesComponent";
// import PatientTreatmentComponent from "./patientTreatmentComponent";

import PatientTreatmentDetails from "./PatientTreatment/PatientTreatmentDetails";
import PatientTreatmentProgressComponent from "./PatientTreatmentProgressComponent";
import { getTreatmentStatus } from "./PatientTreatment/utils";
import { TREATMENT_ORDER_KEY, APPLIANCES_ORDER_KEY } from "constants";

import { getAllTreatmentPlans } from "api/lab";
import { getPatientAlignerTimelineAPI } from "api/aligner";
import { getClinicProducts } from "api/clinic";
import { getRefinementData } from "components/private/patient-detail/PatientTreatment/api.js";

import Prescription from "./Prescription/Prescription";
import PlaceLabOrder from "./PlaceLabOrder/PlaceLabOrder";

import "../../../assets/styles/components/processingLabPatientDetailComponent.scss";
import AddPatientAddress from "./AddPatientAddress";
import { createConfirmConfig } from "components/shared/ShowConfirmModal/config";
import AppButton from "components/shared/AppButton/AppButton";
import { placeOrderItems } from "utils/order";
import ProgressImagesNew from "./ProgressImages/ProgressImagesNew";

const { Panel } = Collapse;

const PUBLIC_API_BASE_URL = process.env.REACT_APP_CLINIC_BASE_URL;

const impressionKitSteps = [
  {
    title: "Ordered",
  },
  {
    title: "Shipped",
  },
  {
    title: "Impressions Received by Lab",
  },
  {
    title: "Lab Processing",
  },
];

const alignerSteps = [
  {
    title: "Approved for printing",
  },
  {
    title: "Printing in progress",
  },
  {
    title: "Shipped",
  },
  {
    title: "Delivered",
  },
];

const ProcessingLabPatientDetailComponent = props => {
  const {
    prescriptionForm,
    setTreatmentId,
    clinic,
    getTreatmentSetupAndPrescription,
    isChildSpinning,
    patientOrders,
    setPatientOrders,
    setTreatmentSetup,
    getImagesAndQuestionnaire,
    scanUrls,
    clinicalFiles,
    imageStageTypes,
    alignerStageDet,
    // alignerRequestBody,
    // setAlignerRequestBody,
    getOrders,
    orderRemakeReasons,
    patientId,
    labType,
    treatmentSetup,
    formData,
    labId,
    treatmentId,
    createImageStageTypes,
    activeTab,
    currentOrderMenuSelected,
    patientInfoState,
    getPatientInformation,
    setTreatmentPrescription,
    setScanUrls,
    clinicInfo,
    patientAddressRef,
    modal,
    patientOrderStatus,
  } = props;
  const childRef = useRef(null);
  const teethChartRef = useRef(null);
  // const printFormRef = useRef(null);
  // const [selectedVersion, setSelectedVersion] = useState(null);
  const [isVersionSelected, setIsVersionSelected] = useState(false);
  const [currentMenuSelected, setCurrentMenuSelected] = useState(
    activeTab ? activeTab : "orders"
  );
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [aligners, setAligners] = useState();
  const [alignerList, setAlignerList] = useState();
  const [currentAlignerStage, setCurrentAlignerStage] = useState();
  const [alignerOrders, setAlignerOrders] = useState([]);
  const [impressionOrders, setImpresssionOrders] = useState([]);
  const [selectedCaseId, setSelectedCaseId] = useState(null);
  const [labOrders, setLabOrders] = useState([]);
  const [showLabOrderModal, setShowLabOrderModal] = useState(false);
  // const [alignerUrls, setAlignerUrls] = useState([]);
  const [activeCaseId, setActiveCaseId] = useState(null);
  const [isOrdersSpinning, setIsOrdersSpinning] = useState(true);
  const [showOlderPlansModal, setShowOlderPlansModal] = useState(false);
  const [isTreatmentSetupSpinning, setIsTreatmentSetupSpinning] =
    useState(true);
  // const [cases, setCases] = useState([]);
  const [wholeTreatmentPlan, setWholeTreatmentPlan] = useState([]);
  const [refinements, setRefinements] = useState([]);
  const [iprData, setIprData] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);
  const [showAddPatientAddress, setShowAddPatientAddress] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [fromOrderTab, setFromOrderTab] = useState(false);
  const [labForm] = Form.useForm();

  const [currentOrderTypeMenuSelected, setCurrentOrderTypeMenuSelected] =
    useState(currentOrderMenuSelected);

  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [showPlaceLabOrder, setShowPlaceLabOrder] = useState(false);
  const [clinicProducts, setClinicProducts] = useState([]);
  const [refinementData, setRefinementData] = useState(null);
  const [activeTreatment, setActiveTreatment] = useState(null);
  const [orderType, setOrderType] = useState(TREATMENT_ORDER_KEY);

  const selectedTPVersionRef = useRef(null);

  const openLabPlaceOrderModal = () => {
    setFromOrderTab(true);
    setShowPlaceLabOrder(true);
  };

  const getRefinementDetails = async patientId => {
    const refinementDetailsRes = await getRefinementData(patientId);
    if (refinementDetailsRes?.data?.data) {
      setRefinementData(refinementDetailsRes.data.data);
    }
  };

  useEffect(() => {
    getRefinementDetails(patientId);
  }, [patientId]);

  useEffect(() => {
    if (patientOrders?.length) {
      parseOrders(patientOrders);
    }
  }, [patientOrders]);

  const parseOrders = orders => {
    const alignerOrderArr = [];
    const impressionOrderArr = [];

    orders.forEach(order => {
      // if (order.order_type_id === 4) {
      //   impressionOrderArr.push(order);
      // } else if (
      //   order.order_type_id === 2 ||
      //   order.order_type_id === 3 ||
      //   order.order_type_id === 1 ||
      //   order.order_type_id === 6
      // ) {
      //   alignerOrderArr.push(order);
      // }
      alignerOrderArr.push(order);
    });

    setAlignerOrders(alignerOrderArr);
    setImpresssionOrders(impressionOrderArr);
    // console.log("c123:", impressionOrderArr);
  };

  const onMenuChanged = event => {
    const selectedMenu = event.target.value;
    setCurrentMenuSelected(selectedMenu);
  };

  const onCurrentOrderTypeMenuChanged = event => {
    const selectedMenu = event.target.value;
    setCurrentOrderTypeMenuSelected(selectedMenu);
  };

  const radioButtonsForTypeImpressions = (
    <Radio.Group
      defaultValue={currentMenuSelected}
      buttonStyle="solid"
      style={{ height: "100%" }}
      onChange={onMenuChanged}

      //   onChange={onMenuChanged}
    >
      <Radio.Button className="patient-details-radio-buttons" value="orders">
        Orders
      </Radio.Button>
      <Radio.Button className="patient-details-radio-buttons" value="photos">
        Photos
      </Radio.Button>
      <Radio.Button className="patient-details-radio-buttons" value="scans">
        Files
      </Radio.Button>
    </Radio.Group>
  );

  const radioButtonsForTypeManufacturing = (
    <Radio.Group
      defaultValue={currentMenuSelected}
      buttonStyle="solid"
      style={{ height: "100%" }}
      onChange={onMenuChanged}

      //   onChange={onMenuChanged}
    >
      <Radio.Button className="patient-details-radio-buttons" value="photos">
        Photos
      </Radio.Button>
      <Radio.Button
        className="patient-details-radio-buttons"
        value="prescription"
      >
        Prescription
      </Radio.Button>
      <Radio.Button className="patient-details-radio-buttons" value="treatment">
        Treatment Setup
      </Radio.Button>
      <Radio.Button className="patient-details-radio-buttons" value="orders">
        Orders
      </Radio.Button>
    </Radio.Group>
  );
  const handleMenuClick = key => {
    openLabPlaceOrderModal();
    setOrderType(key);
  };

  const radioButtonsForTypeBoth = (
    <Row align="middle" justify="space-between" gutter={[16, 16]}>
      <Col>
        <Radio.Group
          defaultValue={currentMenuSelected}
          buttonStyle="solid"
          style={{ height: "100%" }}
          onChange={onMenuChanged}
          //   onChange={onMenuChanged}
        >
          <Radio.Button
            className="patient-details-radio-buttons"
            value="orders"
          >
            Orders
          </Radio.Button>
          <Radio.Button
            className="patient-details-radio-buttons"
            value="prescription"
          >
            Prescription & Files
          </Radio.Button>
          <Radio.Button
            className="patient-details-radio-buttons"
            value="treatment"
          >
            Treatment Setup
          </Radio.Button>
        </Radio.Group>
      </Col>
      <Col>

        {patientOrders?.length && currentMenuSelected === "orders" ? (
          <Dropdown
            menu={{
              items: placeOrderItems(
                handleMenuClick,
                patientOrders,
                treatmentSetup
              ),
            }}
          >
            <AppButton shape="round" type="primary">
              Place Order <DownOutlined />
            </AppButton>
          </Dropdown>
        ) : null}
      </Col>
    </Row>
  );

  const renderOrdersForTypeBoth = () => {
    let disablePlaceOrder = alignerOrders.find(
      obj => obj.order_type_id == 1 && obj.createdFrom == "clinic"
    );

    const refinementOrdered = (refinementData, orders) => {
      if (!wholeTreatmentPlan.length) {
        return false;
      }

      if (!orders.length) {
        return true;
      }

      if (activeTreatment) {
        const order = orders.find(
          order => order.lab_treatment_id === activeTreatment.lab_treatment_id
        );
        if (order) {
          return false;
        }
        return true;
      }

      return false;
    };

    const checkActiveTreatment = activeTp => {
      if (activeTp?.revision_plan_version) {
        return true;
      }

      return false;
    };

    const placeOrderItems = handleOnClick => {
      const options = [
        {
          key: APPLIANCES_ORDER_KEY,
          label: "Appliances",
          onClick: () => handleOnClick(APPLIANCES_ORDER_KEY),
        },
      ];

      if (treatmentSetup?.length) {
        options.unshift({
          key: TREATMENT_ORDER_KEY,
          label: "Treatment",
          onClick: () => handleOnClick(TREATMENT_ORDER_KEY),
        });
      }

      return options;
    };

    return (
      <div style={{ width: "100%" }}>
        <Row
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Radio.Group
            defaultValue={currentOrderTypeMenuSelected}
            buttonStyle="solid"
            style={{ height: "100%" }}
            onChange={onCurrentOrderTypeMenuChanged}

            //   onChange={onMenuChanged}
          >
            <Radio.Button className="patient-details-radio-buttons" value="aligners">
              Applianceeeeee
            </Radio.Button>
            <Radio.Button className="patient-details-radio-buttons" value="impressions">
              Impressions
            </Radio.Button>
          </Radio.Group> */}
          {/* {alignerOrders.length &&
          refinementOrdered(refinementData, alignerOrders) ? (
            <Tooltip
              title={
                !disablePlaceOrder ? "" : "Order already initiated from Clinic."
              }
            >
              <AppButton
                onClick={openLabPlaceOrderModal}
                shape="round"
                type="primary"
                disabled={refinementData ? false : disablePlaceOrder}
              >
                {checkActiveTreatment(activeTreatment)
                  ? "Order Refinement Plan"
                  : "Place Order"}
              </AppButton>
            </Tooltip>
          ) : null} */}
        </Row>

        {currentOrderTypeMenuSelected === "impressions" &&
          (impressionOrders.length > 0 ? (
            <Collapse
              defaultActiveKey={[0]}
              className="site-collapse-custom-collapse"
              style={{ width: "100%", marginTop: "1rem" }}
            >
              {impressionOrders
                .sort((a, b) =>
                  moment(a.product_order_date).utc().valueOf() <
                  moment(b.product_order_date).utc().valueOf()
                    ? 1
                    : -1
                )
                .map((order, index) => {
                  // {console.log("check12:",order,index)}
                  return (
                    <Panel
                      className="site-collapse-custom-panel"
                      header={order.patient_order_id}
                      key={index}
                    >
                      <PatientOrderStepsComponent
                        getOrders={getOrders}
                        steps={impressionKitSteps}
                        orderDetails={order}
                        patientId={patientId}
                        orderRemakeReasons={orderRemakeReasons}
                      ></PatientOrderStepsComponent>
                    </Panel>
                  );
                })}
            </Collapse>
          ) : (
            <Card style={{ width: "100%", marginTop: "1rem" }}>
              No Orders Yet!!
            </Card>
          ))}

        {currentOrderTypeMenuSelected === "aligners" &&
          (alignerOrders.length > 0 ? (
            <Collapse
              defaultActiveKey={[0]}
              className="site-collapse-custom-collapse"
              style={{ width: "100%", marginTop: "1rem" }}
            >
              {alignerOrders.map((order, index) => {
                return (
                  <Panel
                    className="site-collapse-custom-panel"
                    header={order.patient_order_id}
                    key={index}
                  >
                    <PatientAlignerOrderStepsComponent
                      getOrders={getOrders}
                      steps={alignerSteps}
                      orderDetails={order}
                      patientId={patientId}
                      treatmentSetup={treatmentSetup}
                      patientAddressRef={patientAddressRef}
                      clinicInfo={clinicInfo}
                      treatmentPlans={treatmentPlans}
                      getPatientInformation={getPatientInformation}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          ) : (
            <Card
              style={{
                width: "100%",
                minHeight: "34vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Dropdown
                menu={{
                  items: placeOrderItems(handleMenuClick),
                }}
              >
                <AppButton shape="round" type="primary">
                  Place Order <DownOutlined />
                </AppButton>
              </Dropdown>
            </Card>
          ))}
      </div>
    );
  };

  useEffect(() => {
    if (clinic?.clinic_id) {
      (async () => {
        const data = await getClinicProducts(clinic?.clinic_id);

        setClinicProducts(data?.body.clinic_products || []);
      })();
    }
  }, [clinic?.clinic_id]);

  useEffect(() => {
    // console.log(caseId ,prevCountRef.current,"te123")

    if (selectedCaseId) {
      // prevCountRef.current = caseId;
      _getData();
    }
  }, [selectedCaseId]);

  useEffect(() => {
    if (showOlderPlansModal || treatmentSetup?.length) {
      treatmentPlans();
    }
  }, [showOlderPlansModal, treatmentSetup]);

  const _getData = async () => {
    await getPatientInformation();

    const rejectHandler = reason => ({ status: "rejected", reason });

    const resolveHandler = value => ({ status: "fulfilled", value });

    const allSettled = function (promises) {
      const convertedPromises = promises.map(p =>
        Promise.resolve(p).then(resolveHandler, rejectHandler)
      );
      return Promise.all(convertedPromises);
    };

    allSettled([
      _getAlignerDetails(),

      _getPatientTreatmentSetup(),
      treatmentPlans(),
    ])
      .then(res => setIsPageLoading(false))
      .catch(error => {
        setIsPageLoading(false);
        console.log(error);
      });
  };

  const _getAlignerDetails = async () => {
    const params = {
      patientId: patientId,
      clinicId: clinic.clinic_id,
    };
    if (selectedCaseId) params.caseId = selectedCaseId;
    try {
      const alignerTimelineData = await getPatientAlignerTimelineAPI(params);
      setAligners(alignerTimelineData);
    } catch (error) {
      console.log(error);
    }
  };

  const _getOrders = async () => {
    setIsOrdersSpinning(true);
    try {
      const patientOrdersRes = await axios.get(
        `${PUBLIC_API_BASE_URL}/clinic/patientlist/patient/orders/get?patientId=${patientId}`
      );

      const labOrdersRes = await axios.get(
        `${PUBLIC_API_BASE_URL}/clinic/patientlist/lab/orders/get?patientId=${patientId}`
      );
      setPatientOrders(patientOrdersRes.data.body.data);
      setLabOrders(labOrdersRes.data.body.data);
      setIsOrdersSpinning(false);
    } catch (error) {
      console.log(error);
      setIsOrdersSpinning(false);
    }
  };

  const treatmentPlans = async () => {
    const model = { patientId: patientId };
    if (selectedCaseId) model.caseId = selectedCaseId;

    try {
      const res = await getAllTreatmentPlans(model);
      setWholeTreatmentPlan(res?.data?.body?.treatment_setup);

      const refinemnts_not_applied = [];

      res?.data?.body?.treatment_setup.forEach(tp => {
        if (tp?.revision_plan_version && !tp?.refinement_applied) {
          refinemnts_not_applied.push(tp);
        }
      });

      setRefinements(refinemnts_not_applied);

      const approvedTP = res.data?.body?.treatment_setup?.find(
        el => el.approved
      );
      setActiveTreatment(
        approvedTP ? approvedTP : res.data?.body?.treatment_setup[0]
      );
      if (res?.data?.body?.iprData) {
        if (approvedTP) {
          setIprData(approvedTP?.iprData || []);
        }
      }
      if (res?.data?.body?.attachmentData) {
        setAttachmentData(res.data.body.attachmentData);
      } else {
        setAttachmentData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmTreatmentStatus = async (labOrderId, status) => {
    const hide = message.loading("Updating treatment Status", 0);
    const model = {
      lab_treatment_id: labOrderId,
      approval_status: status,
    };

    try {
      const res = await axios.put(
        `${PUBLIC_API_BASE_URL}/clinic/patientlist/treatmentSetup/updateStatus`,
        model
      );
      setTimeout(hide, 0);

      message.success(res.body?.msg);
      _getPatientTreatmentSetup();
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);

      message.error("Something didn't go right. Please try again!");
    }
  };

  const setCaseOnLoad = cases => {
    let currentCaseId = 0;
    cases.forEach(caseObj => {
      if (caseObj.case_id > currentCaseId) currentCaseId = caseObj.case_id;
    });
    setSelectedCaseId(currentCaseId);
    setActiveCaseId(currentCaseId);
  };

  useEffect(() => {
    setIsPageLoading(true);
    if (clinic?.clinic_id) {
      _getCases();
    }
  }, [clinic?.clinic_id]);

  const _getPatientTreatmentSetup = async () => {
    setIsTreatmentSetupSpinning(true);

    const model = { patientId };
    if (selectedCaseId) model.caseId = selectedCaseId;

    try {
      const res = await axios.get(
        `${PUBLIC_API_BASE_URL}/clinic/patientlist/treatmentSetup/get?patientId=${model.patientId}&caseId=${model.caseId}`,
        model
      );

      setTreatmentSetup(res.data.body.treatment_setup);
      res.data.body.treatment_setup[0] &&
        setTreatmentId(res.data.body.treatment_setup[0].lab_treatment_id);
      setIsTreatmentSetupSpinning(false);
    } catch (error) {
      console.log(error);
      setIsTreatmentSetupSpinning(false);
    }
  };

  const _getCases = async () => {
    try {
      const casesRes = await axios.get(
        `${PUBLIC_API_BASE_URL}/clinic/patientlist/getCases?patientId=${patientId}&clinicId=${clinic.clinic_id}`
      );

      const casesArr = casesRes.data.body.data;

      // setCases(casesArr);
      setCaseOnLoad(casesArr);
      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    // console.log("formData", formData)
    if (formData?.prescriptionVersions?.length > 0 && !isVersionSelected) {
      // setSelectedVersion(findMaxPrescriptionVersion(formData?.prescriptionVersions));
    }
  }, [formData]);

  const onEditPrescription = () => {
    setOpenPrescriptionModal(true);
  };

  const callChildMethod = () => {
    if (childRef.current) {
      modal.confirm(childRef.current.getConfirmOrderConfig());
    }
  };

  const confirmPatientAddressModal = () => {
    const confirmPatientAddress = createConfirmConfig({
      title: "Confirm Patient Address",
      width: 520,
      icon: null,
      centered: true,
      content: (
        <div style={{ margin: "20px 0" }}>
          <div>
            <b>
              Please verify the patient's adddress before placing the order:
            </b>
          </div>
          <div style={{ textDecoration: "underline" }}>
            {patientAddressRef.current}
          </div>
        </div>
      ),
      // onOk: () => ,
      onOk: () => callChildMethod(),
      onCancel: () => {},
      okText: "Confirm & Order",
    });
    modal.confirm(confirmPatientAddress);
  };

  const onPlaceOrderClickFromTP = version => {
    selectedTPVersionRef.current = version;
    setShowPlaceLabOrder(true);
  };
  return (
    <div>
      {showPlaceLabOrder && (
        <PlaceLabOrder
          labForm={labForm}
          ref={childRef}
          modal={modal}
          title="Place Lab Order"
          showModal={showPlaceLabOrder}
          setShow={setShowPlaceLabOrder}
          clinicProducts={clinicProducts}
          partneredLabs={[]}
          patientId={patientId}
          clinic={clinicInfo}
          treatmentId={treatmentId}
          patientAddressRef={patientAddressRef}
          patientOrders={patientOrders}
          wholeTreatmentPlan={wholeTreatmentPlan}
          prescriptionForm={prescriptionForm}
          fromPatientJourneyButton={false}
          setFromPatientJourneyButton={() => {}}
          clinicMultipleAddresses={clinicInfo?.multipleAdresses || []}
          getOrders={getOrders}
          patientInfoState={patientInfoState}
          setShowAddPatientAddress={setShowAddPatientAddress}
          confirmPatientAddressModal={confirmPatientAddressModal}
          form={labForm}
          selectedTPVersion={selectedTPVersionRef.current}
          setIsEditAddress={setIsEditAddress}
          setFromOrderTab={setFromOrderTab}
          fromOrderTab={fromOrderTab}
          refinements={refinements}
          orderType={orderType}
          labId={labId}
        />
      )}
      <AddPatientAddress
        content={patientInfoState}
        showModal={showAddPatientAddress}
        onConfirmation={() => {
          console.log("hello");
        }}
        setShow={setShowAddPatientAddress}
        _getPatientInformation={getPatientInformation}
        confirmPatientAddressModal={confirmPatientAddressModal}
        isEditAddress={isEditAddress}
      />
      <Row
        justify="space-between"
        style={{
          height: "2.5rem",
          // width: "calc(95% - 1rem) ",
          // marginLeft: "1rem",
          // paddingRight: "27px",
          paddingLeft: "20px",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <Col lg={24}>
          {labType === 1 && radioButtonsForTypeImpressions}
          {labType === 2 && radioButtonsForTypeManufacturing}
          {labType === 3 && radioButtonsForTypeBoth}
        </Col>
      </Row>
      {currentMenuSelected === "orders" && (
        <Spin spinning={isChildSpinning}>
          {" "}
          <Row
            style={{
              // width: "calc(95% - 1rem) ",
              // marginLeft: "1rem",
              // paddingRight: "27px",
              paddingLeft: "20px",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            {labType === 1 &&
              (impressionOrders.length > 0 ? (
                <Collapse
                  defaultActiveKey={[0]}
                  className="site-collapse-custom-collapse"
                  style={{ width: "100%" }}
                >
                  {impressionOrders
                    .sort((a, b) =>
                      moment(a.product_order_date).utc().valueOf() <
                      moment(b.product_order_date).utc().valueOf()
                        ? 1
                        : -1
                    )
                    .map((order, index) => {
                      return (
                        <Panel
                          className="site-collapse-custom-panel"
                          header={order.patient_order_id}
                          key={index}
                        >
                          <PatientOrderStepsComponent
                            getOrders={getOrders}
                            steps={impressionKitSteps}
                            orderDetails={order}
                            patientId={patientId}
                            orderRemakeReasons={orderRemakeReasons}
                          ></PatientOrderStepsComponent>
                        </Panel>
                      );
                    })}
                </Collapse>
              ) : (
                <Card style={{ width: "100%", marginTop: "1rem" }}>
                  No Orders Yet!!
                </Card>
              ))}

            {labType === 2 &&
              (alignerOrders.length > 0 ? (
                <Collapse
                  defaultActiveKey={[0]}
                  className="site-collapse-custom-collapse"
                  style={{ width: "100%" }}
                >
                  {alignerOrders.map((order, index) => {
                    return (
                      <Panel
                        className="site-collapse-custom-panel"
                        header={order.patient_order_id}
                        key={index}
                      >
                        <PatientAlignerOrderStepsComponent
                          getOrders={getOrders}
                          steps={alignerSteps}
                          orderDetails={order}
                          patientId={patientId}
                          patientAddressRef={patientAddressRef}
                          clinicInfo={clinicInfo}
                          treatmentPlans={treatmentPlans}
                          getPatientInformation={getPatientInformation}
                        />
                      </Panel>
                    );
                  })}
                </Collapse>
              ) : (
                <Card style={{ width: "100%", marginTop: "1rem" }}>
                  <Button>Place Order</Button>
                </Card>
              ))}
            {labType === 3 && renderOrdersForTypeBoth()}
          </Row>
        </Spin>
      )}

      {/* {currentMenuSelected === "scans" && (
        <Row
          style={{
            // width: "calc(95% - 1rem) ",
            // marginLeft: "1rem",
            paddingRight: "27px",
            paddingLeft: "20px",
            width: "100%",
            marginBottom: "1rem",
          }}
        >
          <ViewScanComponent
            scanUrls={scanUrls}
            getImagesAndQuestionnaire={getImagesAndQuestionnaire}
            clinicalFiles={clinicalFiles}
            patientId={patientId}
          ></ViewScanComponent>{" "}
        </Row>
      )} */}

      {currentMenuSelected === "photos" && (
        <Row
          style={{
            // width: "calc(95% - 1rem) ",
            // marginLeft: "1rem",
            // paddingRight: "27px",
            paddingLeft: "20px",
            width: "100%",
            marginBottom: "1rem",
          }}
        >
          <ProgressImageComponent
            imageStageTypes={imageStageTypes}
            alignerStageDet={alignerStageDet}
            createImageStageTypes={createImageStageTypes}
            labId={labId}
            treatmentId={treatmentId}
            patientId={patientId}
            labType={labType}
          ></ProgressImageComponent>{" "}
        </Row>
      )}

      {currentMenuSelected === "prescription" && (
        <Spin spinning={isPageLoading}>
          {" "}
          <Prescription
            clinicInfo={clinicInfo}
            patientInfoState={patientInfoState}
            formData={formData}
            prescriptionForm={prescriptionForm}
            patientId={patientId}
            setTreatmentPrescription={setTreatmentPrescription}
            scanUrls={scanUrls}
            setIsPageLoading={setIsPageLoading}
            setScanUrls={setScanUrls}
            clinicalFiles={clinicalFiles}
            getImagesAndQuestionnaire={getImagesAndQuestionnaire}
          />
        </Spin>
      )}

      {currentMenuSelected === "treatment" && (
        <div
          style={{
            padding: "20px 0 20px 20px",
          }}
        >
          <Row>
            <Col md={12}>
              {/* {treatmentSetup.length > 0 && wholeTreatmentPlan.length > 0 && ( */}
              <div style={{ paddingRight: "10px", height: "100%" }}>
                {/* <Spin spinning={isProgressImagesSpinning}> */}
                {/* <ProgressImages
                    imageStageTypes={imageStageTypes}
                    fullData={fullProgressImageData}
                    patientId={patientId}
                    listOfAligners={alignerList}
                    caseId={activeCaseId}
                    selectedCaseId={selectedCaseId}
                    getAlignerUrls={() => _getAlignerImages()}
                    alignerObject={alignerObject}
                    setAlignerObject={updateAlignerState}
                    getTreatmentId={_getPatientTreatmentSetup}
                    treatmentId={treatmentId}
                    deleteProgressImage={deleteProgressImage}
                    currentAlignerStage={currentAlignerStage}
                    setIsProgressImagesSpinning={setIsProgressImagesSpinning}
                    clinicId={clinic?.clinicId}
                    companyType={clinic?.companyType}
                    impressionCheck={null}
                  /> */}
                <ProgressImagesNew
                  patientId={patientId}
                  caseId={activeCaseId}
                  selectedCaseId={selectedCaseId}
                />

                {/* </Spin> */}
              </div>
              {/* )} */}
            </Col>
            <Col md={12}>
              <div
                style={{
                  height: "100%",
                  background: "#fff",
                  borderRadius: "10px",
                }}
              >
                <Spin spinning={isTreatmentSetupSpinning}>
                  <PatientTreatmentDetails
                    aligners={aligners?.body || null}
                    refinements={refinements}
                    activeTreatment={activeTreatment}
                    onPlaceOrderClickFromTP={onPlaceOrderClickFromTP}
                    wholeTreatmentPlan={wholeTreatmentPlan}
                    treatmentSetup={treatmentSetup}
                    setTreatmentSetup={setTreatmentSetup}
                    isTreatmentCreationAllowed={getTreatmentStatus(formData)}
                    patientInfo={patientInfoState}
                    labOrders={labOrders}
                    patientOrders={patientOrders}
                    setShowLabOrderModal={setShowLabOrderModal}
                    patientId={patientId}
                    labId={labId}
                    getTreatmentSetupAndPrescription={
                      getTreatmentSetupAndPrescription
                    }
                    treatmentPlans={treatmentPlans}
                    confirmTreatmentStatus={confirmTreatmentStatus}
                    setShowOlderPlansModal={setShowOlderPlansModal}
                    getOrders={_getOrders}
                    getPatientInformation={getPatientInformation}
                    iprData={iprData}
                    setIprData={setIprData}
                    attachmentData={attachmentData}
                    setAttachmentData={setAttachmentData}
                    patientOrderStatus={patientOrderStatus}
                    alignerOrders={alignerOrders}
                    setScanUrls={setScanUrls}
                    scanUrls={scanUrls}
                    clinicId={clinic?.clinic_id}
                    getData={_getData}
                    setOrderType={setOrderType}
                    clinicInfo={clinicInfo}
                  />
                </Spin>
              </div>
            </Col>
          </Row>
          {treatmentSetup.length > 0 ? (
            <Row style={{ marginTop: "20px" }}>
              <Col md={24}>
                <div>
                  <Spin spinning={isTreatmentSetupSpinning}>
                    <PatientTreatmentProgressComponent
                      _getAlignerDetails={_getAlignerDetails}
                      //           clinic={clinic}
                      aligners={aligners}
                      //           treatmentId={treatmentId}
                      //           patientInfoState={patientInfoState}
                    />
                  </Spin>
                </div>
              </Col>
            </Row>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ProcessingLabPatientDetailComponent;
