import { TREATMENT_ORDER_KEY, APPLIANCES_ORDER_KEY } from "constants";

export const placeOrderItems = (handleOnClick, patientOrders = [],treatmentSetup=[]) => {
  let hasTpId = false;
  patientOrders.forEach(el => {
    if (el?.lab_treatment_id) {
      hasTpId = true;
    }
  });

  const options = [
    {
      key: APPLIANCES_ORDER_KEY,
      label: "Appliances",
      onClick: () => handleOnClick(APPLIANCES_ORDER_KEY),
    },
  ];

  if (hasTpId||treatmentSetup.length) {
    options.unshift({
      key: TREATMENT_ORDER_KEY,
      label: "Treatment",
      onClick: () => handleOnClick(TREATMENT_ORDER_KEY),
    });
  }

  return options;
};

export const handlePrintingStatusMessage = (
  order_delivery_status_id,
  patient_order_id
) => {
  let msg = `Are you sure you want to update this printing (Order : ${patient_order_id})`;
  if (order_delivery_status_id === 6) {
    msg = `Are you sure you want to start this printing (Order : ${patient_order_id})`;
  } else if (order_delivery_status_id === 7) {
    msg = `Are you sure you want to pause this printing (Order : ${patient_order_id})`;
  } else if (order_delivery_status_id === 9) {
    msg = `Are you sure you want to restart this printing (Order : ${patient_order_id})`;
  }
  return msg;
};

export const getScanName = name => {
  const scanTypeMapping = {
    UpperArch: "Upper Arch",
    LowerArch: "Lower Arch",
    Bitescan: "Bite Scan",
    Bitescan2: "Bite Scan 2",
    BiteScan: "Bite Scan",
    BiteScan2: "Bite Scan 2",
  };

  return scanTypeMapping[name] || name;
};
