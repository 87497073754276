import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Select,
  message,
  Spin,
  Alert
} from "antd";
import { useSelector } from "react-redux";


import {
  getPatientCountryCodesList,
} from "services/redux/selectors/commonSelectors.js";
import { companyType, prefix, country } from "colors-config";
import { addPatientAddressApi } from "api/patient";
import { ReactComponent as BulbIcon } from "../../../assets/images/BulbIcon.svg";

const AddPatientAddress = ({
  content,
  onConfirmation,
  showModal,
  setShow,
  _getPatientInformation,
  confirmPatientAddressModal,
  isEditAddress

}) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSpin, setIsSpin] = useState(false);
  const [form] = Form.useForm();
  const clinic = useSelector((state) => state.clinic);
  // const countryCodes = useSelector(getCountryCodes);
  const patientCountriesList = useSelector(getPatientCountryCodesList);

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  // useEffect(() => {
  //   form.resetFields();
  // }, [countryCodes]);




  useEffect(() => {
    if (patientCountriesList?.length) {
      form.setFieldValue("prefix", prefix)
      form.setFieldValue("country", country)
    }
  }, [patientCountriesList])

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        size="large"
        style={{ width: 90 }}
        defaultValue={prefix}
        showSearch
        filterOption={(input, option) =>
          option.children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >

        {patientCountriesList?.map((country, index) => (
          <Select.Option key={index} value={country.countryCallingCodes}>
            {country.countryCallingCodes}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
  useEffect(() => {
    form.setFieldsValue({
      Address: content?.patient_address,
      city: content?.patient_city,
      county: content?.patient_county,
      country: content?.patient_country,
      Zipcode: content?.patient_postal_code,
    })

    return () => form.resetFields();
  }, [content]);

  const handleOk = async () => {
    form
    .validateFields()
    .then(async (value) => {
     
      const payload = {
        patientId: content?.patient_id,
        address: checkUndefined(value?.Address),
        county: checkUndefined(value?.county),
        zipcode: checkUndefined(value?.Zipcode),
        city: checkUndefined(value?.city),
        country: checkUndefined(value?.country),
      };
      if (clinic?.companyType) {
        payload.companyType = clinic.companyType;
      }

      try {
        setLoading(true)
        await addPatientAddressApi(payload);
        await _getPatientInformation();
        setLoading(false)
        handleCancel();
        confirmPatientAddressModal();
       
      } catch (error) {
        message.error(error.message);
        setLoading(false)
      }
     

    }).catch(err=>{
      console.log("err");
    })
  };

  function checkUndefined(val) {
    return val ? val : "";
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };



  return (
    <>
      <Modal
        title={"Update Patient Address"}
        width={700}
        confirmLoading={loading}
        open={isModalVisible}
        okText="Update"
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
        zIndex={1003}
      >
        {isSpin ? (
          <Spin spinning={isSpin} />
        ) : (
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            // initialValues={loadValue}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  className="labelClass"
                  name="Address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                      message: "Address is required",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="sectionInputTypeAddPatient">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "City is required",
                      },
                    ]}
                    className="labelClass"
                    name="city"
                    label="City"
                  >
                    <Input size="large" className="inputTypeClassAddPatient" />
                  </Form.Item>
                  <Form.Item
                    className="labelClass"
                    name="county"
                    label="County/Province/State"
                    rules={[
                      {
                        required: true,
                        message: "County/Province/State is required",
                      },
                    ]}
                  >
                    <Input size="large" className="inputTypeClassAddPatient" />
                  </Form.Item>
                </div>
                <div className="sectionInputTypeAddPatient">
                  <Form.Item
                    className="labelClass"
                    name="country"
                    label="Country"
                    rules={[
                      {
                        required: true,
                        message: "Country is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      size="large"
                      placeholder="Country"
                      defaultActiveFirstOption={false}
                      style={{ width: "300px", textAlign: "left" }}
                      notFoundContent={null}
                      options={patientCountriesList?.map((country, i) => {
                        return {
                          ...country,
                          value: country.name,
                          title: country.name,
                          label: (
                            <>
                              <span className="emoji-font">
                                {country.emoji}
                              </span>
                              {` ${country.name}`}
                            </>
                          ),
                          key: i,
                        };
                      })}
                      labelRender={(props) => {
                        return props.title;
                      }}
                      filterOption={(input, option) => {
                        const lowerCasedInput = input.toLowerCase();
                        return option.name
                          .toLowerCase()
                          .includes(lowerCasedInput);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="labelClass"
                    name="Zipcode"
                    label={"Post/Zip/EIR Code"}
                    rules={[
                      {
                        required: true,
                        message: `Post/Zip/EIR Code is required`,
                      },
                    ]}
                  >
                    <Input size="large" className="inputTypeClassAddPatient" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Alert
                  className="new-alert-type1"
                  message="Patient's address is required to place an order at the patient's address"
                  type="info"
                  showIcon
                  icon={<BulbIcon />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AddPatientAddress;
